import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { ConstructionSite, ReportParameters, ReportQueryResourceService } from '../../../shared/api';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ReportComponent } from '../report/report.component';
import { Report } from '../../../shared/api/model/report';
import { DownloadFileService } from '../../../shared/services/download-file.service';

@Component({
  selector: 'fc-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }]
})
export class ReportListComponent implements OnInit {
  reports: Report[];
  currentReport: Report;
  file: string;
  page = 1;
  pageNumber = 0;
  isLoading = true;

  constructionSite: ConstructionSite;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private reportQueryResourceService: ReportQueryResourceService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private downloadService: DownloadFileService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    if (this.constructionSite) {
      this.reportQueryResourceService.getReports(this.constructionSite.id).subscribe(
        (reports: any) => {
          this.isLoading = false;
          this.reports = reports;
          if (reports.length) {
            this.currentReport = reports[0];
            this.file = this.currentReport.fileUrl;
          } else {
            this.openCreateModal();
          }
        },
        () => {
          this.isLoading = false;
          this.toasterService.error(
            this.translateService.instant('construction-site.components.report.generating.error')
          );
        }
      );
    }
  }

  /* istanbul ignore next */
  pageRendered($event: CustomEvent) {
    this.pageNumber = $event['pageNumber'];
  }

  /* istanbul ignore next */
  openCreateModal() {
    const modalRef = this.modalService.open(ReportComponent, { windowClass: 'fullscreen' });
    modalRef.componentInstance.constructionSite = { ...this.constructionSite };
    modalRef.result.then(() => {
      this.refresh();
    });
  }

  updateCurrent(report: Report) {
    this.currentReport = report;
    this.file = report.fileUrl;
  }

  /* istanbul ignore next */
  downloadFile() {
    this.toasterService.info(this.translateService.instant('construction-site.components.report-list.download.info'));
    this.downloadService
      .download(
        this.currentReport.fileUrl,
        `${'CR#' + this.currentReport.num + ' - Compte Prorata ' + this.constructionSite.name + '.pdf'}`
      )
      .subscribe(() => {
        this.toasterService.success(
          this.translateService.instant('construction-site.components.report-list.download.success')
        );
      });
  }
}
