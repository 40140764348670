/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportExpense { 
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    id?: string;
    expensesCompletionPercent?: number;
    expensesPercent?: number;
    estimatedBudgetPercent?: number;
    expensesAmount?: number;
    estimatedBudgetAmount?: number;
    date?: string;
    count?: number;
}

