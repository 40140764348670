import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../../environments/environment';
import { every } from 'lodash';
import { MailBoxLayerResponse } from '../../shared/model/mail-box-layer-response';
@Injectable({
  providedIn: 'root'
})
export class EmailCheckerService {
  constructor() {}

  async verify(
    email: string
  ): Promise<boolean | { isFormatValid: boolean; isMxValid: boolean; isDisposable: boolean; isScoreValid: boolean }> {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return {
      isFormatValid: validRegex.test(email),
      isMxValid: true,
      isDisposable: false,
      isScoreValid: true
    };
  }

  /* istanbul ignore next */
  areAllValid(emails: { email: string; isMxValid: boolean; isFormatValid: boolean; isDisposable: boolean }[]) {
    return every(emails, (email) => email.email && email.isMxValid && email.isFormatValid && !email.isDisposable);
  }
}
