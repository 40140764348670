import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigModule } from './app-config.module';
import { CoreModule } from './core/core.module';
import { ValdemortModule } from 'ngx-valdemort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './shared/interceptors/http-error-interceptor';
import { SentryErrorHandler } from './shared/interceptors/error-handler';
import { CrispComponent } from './core/crisp/crisp.component';
import { PublicModule } from './features/public/public.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CoreModule,
    AppConfigModule,
    NgbPaginationModule,
    AppRoutingModule,
    ValdemortModule,
    BrowserAnimationsModule,
    NgbModule,
    PublicModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [AppComponent, CrispComponent],
  exports: [CrispComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ]
})
export class AppModule {}
