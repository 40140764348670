import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../../features/account/account.service';
import { captureMessage, Severity } from '@sentry/browser';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        captureMessage(error.message, Severity.Error);
        if (error instanceof HttpErrorResponse && error.status === 401) {
          /* istanbul ignore next */
          if (this.accountService.isAuthenticated()) {
            this.accountService.logout();
          }
        }
        return throwError(error);
      })
    );
  }
}
