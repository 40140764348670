import { Injectable } from '@angular/core';
import { Lot, LotCommandResourceService } from '../../shared/api';
import { APP_EVENTS } from '../../shared/event/event.enum';

@Injectable({
  providedIn: 'root'
})
export class LotServiceService {
  constructor(private lotCommandResourceService: LotCommandResourceService) {}

  /* istanbul ignore next */
  async updateLot(lotToUpdate: Lot, constructionSiteId) {
    return await this.lotCommandResourceService
      .updateLot(constructionSiteId, lotToUpdate.id, lotToUpdate, 'response')
      .toPromise();
  }

  /* istanbul ignore next */
  async createLot(lotToCreate: Lot, constructionSiteId) {
    return await this.lotCommandResourceService.addLot(constructionSiteId, lotToCreate, 'response').toPromise();
  }
}
