/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserPreferences } from './userPreferences';
import { UserOrganisation } from './userOrganisation';
import { Organisation } from './organisation';
import { BankInformation } from './bankInformation';


export interface User { 
    id?: string;
    login: string;
    firstName?: string;
    lastName?: string;
    role?: User.RoleEnum;
    email?: string;
    imageUrl?: string;
    activated?: boolean;
    pending?: boolean;
    hasRestriction?: boolean;
    registeredMailCounter?: number;
    limit?: number;
    langKey?: string;
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    authorities?: Set<string>;
    organisations?: Array<Organisation>;
    organisation?: UserOrganisation;
    bankInformations?: Array<BankInformation>;
    userPreferences?: UserPreferences;
    limitExceeded?: boolean;
    organisationItems?: Array<Organisation>;
    organisationName?: string;
    admin?: boolean;
    registeredMailLimitExceeded?: boolean;
    managementCompensationDeducted?: boolean;
}
export namespace User {
    export type RoleEnum = 'MANAGER' | 'MEMBER' | 'PROJECT_OWNER' | 'GENERAL_CONTRACTOR';
    export const RoleEnum = {
        Manager: 'MANAGER' as RoleEnum,
        Member: 'MEMBER' as RoleEnum,
        ProjectOwner: 'PROJECT_OWNER' as RoleEnum,
        GeneralContractor: 'GENERAL_CONTRACTOR' as RoleEnum
    };
}


