/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Lot } from './lot';
import { Document } from './document';
import { EstimatedBudget } from './estimatedBudget';
import { EstimatedBudgetDetail } from './estimatedBudgetDetail';


export interface Agreement { 
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    version?: number;
    name?: string;
    estimatedBudget?: number;
    mode?: Agreement.ModeEnum;
    managerCompensation?: number;
    commitee?: Array<string>;
    documents?: Array<Document>;
    lots?: Array<Lot>;
    otherDocuments?: Array<Document>;
    estimatedBudgetDetail?: EstimatedBudgetDetail;
    estimatedBudgetStats?: EstimatedBudget;
    isDraft?: boolean;
    isImported?: boolean;
    isTransfer?: boolean;
    delay?: Agreement.DelayEnum;
    isPrivate?: boolean;
    expenseHtmlContent?: string;
    commentHtmlContent?: string;
    signatureUrl?: string;
    isBpFilled?: boolean;
}
export namespace Agreement {
    export type ModeEnum = 'REAL' | 'FIXED' | 'REAL_FIXED' | 'LOT_FIXED';
    export const ModeEnum = {
        Real: 'REAL' as ModeEnum,
        Fixed: 'FIXED' as ModeEnum,
        RealFixed: 'REAL_FIXED' as ModeEnum,
        LotFixed: 'LOT_FIXED' as ModeEnum
    };
    export type DelayEnum = '30_DAYS' | 'TODAY' | 'END_OF_MONTH' | '45_DAYS' | 'END_OF_NEXT_MONTH';
    export const DelayEnum = {
        _30Days: '30_DAYS' as DelayEnum,
        Today: 'TODAY' as DelayEnum,
        EndOfMonth: 'END_OF_MONTH' as DelayEnum,
        _45Days: '45_DAYS' as DelayEnum,
        EndOfNextMonth: 'END_OF_NEXT_MONTH' as DelayEnum
    };
}


