import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { JhiEventManager } from 'ng-jhipster';
import { APP_EVENTS } from '../../../../../shared/event/event.enum';
import {
  ConstructionSite,
  InvitationCommandResourceService,
  Invitations,
  Lot,
  LotMemberCommandResourceService,
  LotQueryResourceService,
  Member,
  MemberQueryResourceService
} from '../../../../../shared/api';
import { AuthorityEnum } from '../../../../account/shared/models/authority.enum';
import RoleEnum = Lot.RoleEnum;

@Component({
  selector: 'fc-construction-site-invitation-modal',
  templateUrl: './construction-site-invitation-modal.component.html',
  styleUrls: ['./construction-site-invitation-modal.component.scss']
})
export class ConstructionSiteInvitationModalComponent implements OnInit {
  constructionSite: ConstructionSite;
  lot: Lot = { name: null, description: null, role: RoleEnum.Member };
  lots: Lot[];
  members: Member[];
  membersInvited: Member[];
  authorityEnum = AuthorityEnum; // List of authorities
  showInvitationForm = false;
  page = 'member';

  constructor(
    public activeModal: NgbActiveModal,
    private memberQueryResourceService: MemberQueryResourceService,
    private lotMemberCommandResourceService: LotMemberCommandResourceService,
    private invitationCommandResourceService: InvitationCommandResourceService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private eventManager: JhiEventManager,
    private lotQueryResourceService: LotQueryResourceService
  ) {}

  ngOnInit() {
    if (this.constructionSite) {
      this.lotQueryResourceService.getLots(this.constructionSite.id, false).subscribe((lots) => {
        this.lots = lots;
      });
      this.loadMembers();
    }
  }

  sendInvitation(invitationWithLot: { invitations: Invitations; lotId?: string }) {
    let invitationObservable;

    if (invitationWithLot.lotId) {
      invitationObservable = this.lotMemberCommandResourceService.sendLotInvitations(
        this.constructionSite.id,
        invitationWithLot.lotId,
        invitationWithLot.invitations
      );
    } else {
      invitationObservable = this.invitationCommandResourceService.sendInvitations(
        this.constructionSite.id,
        invitationWithLot.invitations
      );
    }

    invitationObservable.subscribe(
      () => {
        this.toasterService.success(
          this.translateService.instant('construction-site.components.form.messages.invitation-success')
        );
        this.changeMembers();
        this.showInvitationForm = false;
      },
      () =>
        this.toasterService.error(
          this.translateService.instant('construction-site.components.form.messages.invitation-error')
        )
    );
  }

  remindInvitation() {
    this.page = 'guest';
    const invitationObservable = this.invitationCommandResourceService.remindInvitations(this.constructionSite.id);

    invitationObservable.subscribe(
      () => {
        this.toasterService.success(
          this.translateService.instant('construction-site.components.form.messages.invitation-success')
        );
        this.changeMembers();
        this.showInvitationForm = false;
      },
      () =>
        this.toasterService.error(
          this.translateService.instant('construction-site.components.form.messages.invitation-error')
        )
    );
  }

  changeMembers() {
    this.loadMembers();
    this.eventManager.broadcast(APP_EVENTS.CONSTRUCTION_SITE_UPDATED);
    this.eventManager.broadcast(APP_EVENTS.MEMBERS_SITE);
  }

  private loadMembers() {
    this.memberQueryResourceService.getMembers(this.constructionSite.id).subscribe((members) => {
      this.members = members.filter((member) => member.user && member.user.activated);
      this.membersInvited = members.filter((member) => !member.user);
    });
  }
}
