import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasMemberWithRole'
})
export class HasMemberWithRolePipe implements PipeTransform {
  constructor() {}

  transform(entity, role: string): boolean {
    return entity && entity.members && entity.members.some((member) => member.role === role);
  }
}
