// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLanguage: 'fr',
  uploadPictureMaxSize: 0.5,
  uploadBinaryFileMaxSize: 8,
  keycloakUrl: 'https://auth.dev.thefaircost.com/auth/realms/faircost',
  gaKey: null,
  sentryDsn: 'https://d0811bece63248b6b75c0aec8bb51206@o454686.ingest.sentry.io/5445121',
  logrocketKey: 'gnmk2a/faircost-webapp-dev',
  host: 'dev.thefaircost.com',
  crispWebsiteID: '57eae051-1350-4439-9869-f0db3be08fbf',
  mailBoxLayerHost:
    'https://apilayer.net/api/check?access_key=26d5fbb2f226fb3e1da2f152b3f6316c&email={email}&smtp=1&format=1',
  mailBoxLayerValidScore: 0.33,
  agreement: [
    {
      url: 'https://dev.thefaircost.com/images/convention.docx',
      name: 'agreement.docx',
      icon: 'doc',
      label: 'agreement'
    },
    {
      url: 'https://dev.thefaircost.com/images/distribution.xlsx',
      name: 'distribution.xlsx',
      icon: 'xlsx',
      label: 'distribution'
    },
    {
      url: 'https://dev.thefaircost.com/images/generalConditions.docx',
      name: 'generalConditions.docx',
      icon: 'doc',
      label: 'generalConditions'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
