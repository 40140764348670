import { NgModule } from '@angular/core';
import { AvatarThumbnailComponent } from './thumbnail/avatar-thumbnail.component';
import { GravatarModule } from 'ngx-gravatar';
import { AccountSharedModule } from '../../../features/account/shared/account-shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, GravatarModule, AccountSharedModule],
  declarations: [AvatarThumbnailComponent],
  exports: [AvatarThumbnailComponent]
})
export class AvatarModule {}
