/* istanbul ignore file */

import { Pipe, PipeTransform } from '@angular/core';
import { AccountService } from '../../features/account/account.service';

@Pipe({
  name: 'isMemberOf'
})
export class MemberOfPipe implements PipeTransform {
  constructor(private accountService: AccountService) {}

  transform(entity): boolean {
    return this.accountService.isMemberOf(entity);
  }
}
