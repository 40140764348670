/* istanbul ignore file */

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ConstructionSite,
  Lot,
  LotQueryResourceService,
  ReportCommandResourceService,
  ReportParameters
} from '../../../shared/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DownloadFileService } from '../../../shared/services/download-file.service';
import { PDF_MIME_TYPES } from '../../../app.constants';
import { Editor } from 'ngx-editor';
import { SITUATION_CONTENT } from './report-constants';
import moment from 'moment/moment';

@Component({
  selector: 'fc-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  report: ReportParameters = {
    displayAgreement: true,
    displayExpense: true,
    displayPayment: true,
    displayComment: false,
    isMembersListsActivated: false,
    excusedLots: [],
    presentLots: [],
    missingLots: [],
    comment: '',
    sendToLot: true,
    sendToProjectOwner: true,
    sendToGeneralContractor: true
  };
  editor: Editor;
  hasToDownloadPDF = false;
  file: string;
  reader;
  page = 1;
  pageNumber = 0;
  isLoading = true;
  currentFile: File;
  listMembers: {
    allLots: Lot[];
    allPresentLots: Lot[];
    present: string[];
    allMissingLots: Lot[];
    missing: string[];
    allExcusedLots: Lot[];
    excused: string[];
  } = {
    allLots: [],
    allPresentLots: [],
    present: [],
    allMissingLots: [],
    missing: [],
    allExcusedLots: [],
    excused: []
  };

  constructionSite: ConstructionSite;

  constructor(
    public activeModal: NgbActiveModal,
    private reportCommandResourceService: ReportCommandResourceService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private downloadService: DownloadFileService,
    private lotQueryResourceService: LotQueryResourceService
  ) {}

  ngOnInit() {
    this.editor = new Editor();
    this.report.situation = SITUATION_CONTENT.replace('#date#', moment().format('DD/MM/YYYY'));
    this.generate();
  }

  readFile() {
    if (this.reader) {
      this.file = this.reader.result as string;
    }
  }

  generate() {
    if (this.constructionSite) {
      this.isLoading = true;
      this.page = 1;
      this.toasterService.info(this.translateService.instant('construction-site.components.report.generating.init'));

      if (this.report.isMembersListsActivated) {
        this.report.missingLots = this.listMembers.allLots.filter((lot) => this.listMembers.missing.includes(lot.id));
        this.report.excusedLots = this.listMembers.allLots.filter((lot) => this.listMembers.excused.includes(lot.id));
        this.report.presentLots = this.listMembers.allLots.filter((lot) => this.listMembers.present.includes(lot.id));
      }

      this.reportCommandResourceService.generate(this.constructionSite.id, this.report).subscribe(
        (file: any) => {
          this.currentFile = file;
          this.isLoading = false;
          this.reader = new FileReader();
          this.reader.readAsDataURL(file);
          this.reader.onload = this.readFile.bind(this);
          this.toasterService.success(
            this.translateService.instant('construction-site.components.report.generating.success')
          );
        },
        () => {
          this.isLoading = false;
          this.toasterService.error(
            this.translateService.instant('construction-site.components.report.generating.error')
          );
        }
      );
    }
  }

  sendReport(event: MouseEvent) {
    event.stopPropagation();

    if (this.hasToDownloadPDF) {
      this.downloadService.downloadBinary(
        this.currentFile as any,
        PDF_MIME_TYPES[0],
        `${'CR#' + this.constructionSite.reportNumber + ' - Compte Prorata ' + this.constructionSite.name + '.pdf'}`
      );
    }

    this.isLoading = true;
    this.toasterService.info(this.translateService.instant('construction-site.components.report.sending.init'));
    this.reportCommandResourceService.sendReport(this.constructionSite.id, this.report).subscribe(
      () => {
        this.isLoading = false;
        this.activeModal.close();
        this.toasterService.success(
          this.translateService.instant('construction-site.components.report.sending.success')
        );
      },
      () => {
        this.isLoading = false;
        this.toasterService.error(this.translateService.instant('construction-site.components.report.sending.error'));
      }
    );
  }

  /* istanbul ignore next */
  downloadFile() {
    this.toasterService.info(this.translateService.instant('construction-site.components.report-list.download.info'));
    this.downloadService.downloadBinary(
      this.currentFile as any,
      PDF_MIME_TYPES[0],
      `${'CR#' + this.constructionSite.reportNumber + ' - Compte Prorata ' + this.constructionSite.name + '.pdf'}`
    );
  }

  pageRendered($event: CustomEvent) {
    this.pageNumber = $event['pageNumber'];
  }

  activeMembersLists() {
    if (this.report.isMembersListsActivated) {
      this.lotQueryResourceService.getLots(this.constructionSite.id, true).subscribe((lots) => {
        this.listMembers.allLots = this.listMembers.allExcusedLots = this.listMembers.allPresentLots = this.listMembers.allMissingLots = lots;
        this.listMembers.missing = lots.map((lot) => lot.id);
      });
    } else {
      this.listMembers.allExcusedLots = this.listMembers.allPresentLots = this.listMembers.allMissingLots = [];
      this.listMembers.excused = this.listMembers.present = this.listMembers.missing = [];
    }
  }

  removeExcusedItem(item: any) {
    this.listMembers.missing.push(item.id);
    this.listMembers.excused = this.listMembers.excused.filter((id) => id !== item.id);
    this.generate();
  }

  removePresentItem(item: any) {
    this.listMembers.missing.push(item.id);
    this.listMembers.present = this.listMembers.present.filter((id) => id !== item.id);
    this.generate();
  }

  addPresentItem(item: any) {
    this.listMembers.missing = this.listMembers.missing.filter((id) => id !== item.id);
    this.listMembers.excused = this.listMembers.excused.filter((id) => id !== item.id);
    this.generate();
  }

  addExcusedItem(item: any) {
    this.listMembers.missing = this.listMembers.missing.filter((id) => id !== item.id);
    this.listMembers.present = this.listMembers.present.filter((id) => id !== item.id);
    this.generate();
  }
}
