/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RegisteredMailDTO } from './registeredMailDTO';


export interface SignatoryRemindHistory { 
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    id?: string;
    mode?: SignatoryRemindHistory.ModeEnum;
    registeredMail?: RegisteredMailDTO;
    date?: string;
}
export namespace SignatoryRemindHistory {
    export type ModeEnum = 'EMAIL' | 'MAIL';
    export const ModeEnum = {
        Email: 'EMAIL' as ModeEnum,
        Mail: 'MAIL' as ModeEnum
    };
}


