import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';

@Component({
  selector: 'fc-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
  titleKey: string;
  guideKey: string;
  params: {
    nb?: number;
  } = {};
  expectedConfirmationValue: string;
  confirmationValue: string;
  buttonKey: string;
  revertKey: string;
  hasCloseButton = false;
  toggleKey: string = null;
  toggleValue = false;

  constructor(public activeModal: NgbActiveModal) {}

  protected readonly generate = generate;
}
