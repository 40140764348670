import { NgModule } from '@angular/core';
import { ManagerOfPipe } from './manager-of.pipe';
import { ValAbsolutePipe } from './val-absolute.pipe';
import { MemberOfPipe } from './member-of.pipe';
import { HasMemberWithRolePipe } from './has-member-with-role.pipe';

@NgModule({
  declarations: [ManagerOfPipe, MemberOfPipe, ValAbsolutePipe, HasMemberWithRolePipe],
  exports: [ManagerOfPipe, MemberOfPipe, ValAbsolutePipe, HasMemberWithRolePipe]
})
export class PipeModule {}
