/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Lot } from './lot';
import { User } from './user';
import { ConstructionSite } from './constructionSite';
import { FundingAppealClient } from './fundingAppealClient';
import { FundingAppealLot } from './fundingAppealLot';
import { Member } from './member';


export interface RegisteredMailDTO { 
    object?: string;
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    type?: RegisteredMailDTO.TypeEnum;
    state?: RegisteredMailDTO.StateEnum;
    refusedDate?: string;
    negligenceDate?: string;
    tsEvDate?: string;
    content?: string;
    constructionSite?: ConstructionSite;
    constructionSites?: Array<ConstructionSite>;
    fundingAppealLots?: Array<FundingAppealLot>;
    fundingAppealClients?: Array<FundingAppealClient>;
    user?: User;
    lot?: Lot;
    member?: Member;
    id?: string;
    itemIds?: Array<string>;
    fileUrl?: string;
    filename?: string;
    lastname?: string;
    firstname?: string;
    email?: string;
    signatoryName?: string;
    signatoryContent?: string;
    hasToSendCopy?: boolean;
    displayLogo?: boolean;
    displayFooter?: boolean;
    proof?: string;
    organisation?: string;
}
export namespace RegisteredMailDTO {
    export type TypeEnum = 'REMIND' | 'REMIND_2' | 'INJUNCTION' | 'SIGNATORY' | 'OTHER';
    export const TypeEnum = {
        Remind: 'REMIND' as TypeEnum,
        Remind2: 'REMIND_2' as TypeEnum,
        Injunction: 'INJUNCTION' as TypeEnum,
        Signatory: 'SIGNATORY' as TypeEnum,
        Other: 'OTHER' as TypeEnum
    };
    export type StateEnum = 'dp' | 'ev' | 'AR' | 'refused' | 'negligence' | 'bounced' | 'fail' | 'consent' | 'optimal_choice_done' | 'cancelled_by_user' | 'cancelled_after_7_days' | 'recipient_update' | 'price_set_to_free' | 'PENDING' | 'COMPLETE';
    export const StateEnum = {
        Dp: 'dp' as StateEnum,
        Ev: 'ev' as StateEnum,
        Ar: 'AR' as StateEnum,
        Refused: 'refused' as StateEnum,
        Negligence: 'negligence' as StateEnum,
        Bounced: 'bounced' as StateEnum,
        Fail: 'fail' as StateEnum,
        Consent: 'consent' as StateEnum,
        OptimalChoiceDone: 'optimal_choice_done' as StateEnum,
        CancelledByUser: 'cancelled_by_user' as StateEnum,
        CancelledAfter7Days: 'cancelled_after_7_days' as StateEnum,
        RecipientUpdate: 'recipient_update' as StateEnum,
        PriceSetToFree: 'price_set_to_free' as StateEnum,
        Pending: 'PENDING' as StateEnum,
        Complete: 'COMPLETE' as StateEnum
    };
}


