import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fc-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  videoParams = {
    isCollapsed1: true,
    isCollapsed2: true,
    isCollapsed3: true,
    isCollapsed4: true,
    isCollapsed5: true,
    isCollapsed6: true,
    isCollapsed7: true,
    isCollapsed8: true
  };

  constructor(public activeModal: NgbActiveModal) {}

  openChat() {
    window['$crisp'].push(['do', 'chat:show']);
    window['$crisp'].push(['do', 'chat:open']);
    this.activeModal.close();
  }

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
}
