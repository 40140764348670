import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
import { Member, MemberId, User } from '../../../api';

export interface AvatarThumbnailConfig {
  max?: number;
  size?: number;
  isSignatory?: (member) => boolean;
  customClass?: string;
}

@Component({
  selector: 'fc-avatar-thumbnail',
  templateUrl: './avatar-thumbnail.component.html',
  styleUrls: ['./avatar-thumbnail.component.scss']
})
export class AvatarThumbnailComponent implements OnChanges {
  @Input() members: Member[] = [];
  @Input() config: AvatarThumbnailConfig = {};
  avatars: User[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.users && !isEqual(changes.users.currentValue, changes.users.previousValue)) {
      if (!this.config.max) {
        this.config.max = 5;
      }
      if (!this.config.size) {
        this.config.size = 30;
      }

      this.members = this.members ? this.members.slice(0, this.config.max) : [];
    }
  }

  getColorNum(letter) {
    if (letter.match(/[a-f]/i) !== null) {
      return 1;
    } else if (letter.match(/[g-l]/i) !== null) {
      return 2;
    } else if (letter.match(/[m-r]/i) !== null) {
      return 3;
    } else if (letter.match(/[s-x]/i) !== null) {
      return 4;
    } else if (letter.match(/[y-z]/i) !== null) {
      return 5;
    } else {
      return 0;
    }
  }
}
