import { NgModule } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { NgJhipsterModule, translatePartialLoader } from 'ng-jhipster';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { ApiModule, Configuration } from './shared/api';

export function apiModuleConfiguration(): Configuration {
  return new Configuration({
    basePath: ''
  });
}

@NgModule({
  imports: [
    SharedModule.forRoot(),
    ApiModule.forRoot(apiModuleConfiguration),
    NgJhipsterModule.forRoot({
      i18nEnabled: true,
      defaultI18nLang: 'fr'
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.gaKey),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      closeButton: true
    })
  ],
  exports: [NgJhipsterModule, TranslateModule]
})
export class AppConfigModule {}
