import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ConstructionSite,
  ExpensesStats,
  ExpensesStatsQueryResourceService,
  FundingAppealsStats,
  FundingAppealsStatsIds,
  FundingAppealsStatsQueryResourceService
} from '../../../api';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { JhiEventManager } from 'ng-jhipster';
import { APP_EVENTS } from '../../../event/event.enum';

@Component({
  selector: 'fc-funding-appeals-kpi',
  templateUrl: './funding-appeals-kpi.component.html',
  styleUrls: ['./funding-appeals-kpi.component.scss']
})
export class FundingAppealsKpiComponent implements OnInit, OnDestroy {
  @Output() itemsCount: EventEmitter<{ paid: number; pending: number }> = new EventEmitter();
  @Input() constructionSite: ConstructionSite;
  @Input() fundingAppealIds: FundingAppealsStatsIds;
  @Input() calculateExpenseStats: boolean;
  @Input() config: {
    displayEmit: boolean;
    displayPaid: boolean;
    displayLate: boolean;
    displayPending: boolean;
  } = {
    displayEmit: true,
    displayPaid: true,
    displayLate: true,
    displayPending: true
  };
  fundingAppealsStats: FundingAppealsStats;
  expensesStats: ExpensesStats;
  isTaxesIncluded = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private fundingAppealsStatsQueryResourceService: FundingAppealsStatsQueryResourceService,
    private expensesStatsQueryResourceService: ExpensesStatsQueryResourceService,
    private eventManager: JhiEventManager
  ) {}

  ngOnInit() {
    this.refresh();
    /* istanbul ignore next */
    this.subscriptions.push(this.eventManager.subscribe(APP_EVENTS.FUNDING_APPEALS_KPI_UPDATED, () => this.refresh()));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /* istanbul ignore next */
  refresh() {
    if (this.constructionSite && this.calculateExpenseStats) {
      /* istanbul ignore next */
      this.expensesStatsQueryResourceService
        .getExpensesStates(this.constructionSite.id, this.isTaxesIncluded)
        .subscribe((stats) => (this.expensesStats = stats));
    }

    if (this.fundingAppealIds && this.fundingAppealIds) {
      this.fundingAppealsStatsQueryResourceService
        .getGlobalStats(this.isTaxesIncluded, this.fundingAppealIds)
        .subscribe((stats) => (this.fundingAppealsStats = stats));
    } else {
      this.fundingAppealsStatsQueryResourceService
        .getStatsBySiteId(this.constructionSite.id, this.isTaxesIncluded)
        .subscribe((stats) => {
          this.fundingAppealsStats = stats;
          this.itemsCount.emit({ paid: stats.paid.count, pending: stats.pendingLot.count });
        });
    }
  }
}
