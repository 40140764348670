import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fc-fc-pagination',
  templateUrl: './fc-pagination.component.html',
  styleUrls: ['./fc-pagination.component.scss']
})
export class FcPaginationComponent implements OnInit {
  @Input() nbPages: number;
  @Input() currentPage: number;
  @Output() updatePage: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  getPages(): number[] {
    return Array.from({ length: this.nbPages }, (_, i) => i + 1);
  }

  changePage(p: number) {
    this.currentPage = p;
    this.updatePage.emit(p);
  }
}
