import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSharedModule } from '../../features/account/shared/account-shared.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationModalService } from './confirmation/services/confirmation-modal.service';
import { SharedLibsModule } from '../shared-libs.module';
import { UiSwitchComponent } from './ui/switch/ui-switch.component';
import { AvatarModule } from './avatar/avatar.module';
import { UploadComponent } from './upload/upload.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NoFutureValidator } from './validator/no-future.validator';
import { NoBeforeValidator } from './validator/noBefore/no-before.validator';
import { FundingAppealsKpiComponent } from './funding-appeals/kpi/funding-appeals-kpi.component';
import { NgxEditorModule } from 'ngx-editor';
import { BillingEditComponent } from './billing-edit/billing-edit.component';
import { MessageComponent } from './message/message.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MonitoringFilterComponent } from '../../features/monitoring/monitoring/monitoring-filter/monitoring-filter.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { FileBannerComponent } from './file-banner/file-banner.component';
import { FcPaginationComponent } from './fc-pagination/fc-pagination.component';

@NgModule({
  declarations: [
    UiSwitchComponent,
    ConfirmationComponent,
    UploadComponent,
    NoFutureValidator,
    NoBeforeValidator,
    FundingAppealsKpiComponent,
    BillingEditComponent,
    MessageComponent,
    ProgressBarComponent,
    FileBannerComponent,
    FcPaginationComponent
  ],
  imports: [
    CommonModule,
    AccountSharedModule,
    SharedLibsModule,
    AvatarModule,
    NgxDropzoneModule,
    PdfViewerModule,
    NgxEditorModule,
    NgbDropdownModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [ConfirmationModalService],
  exports: [
    ConfirmationComponent,
    UiSwitchComponent,
    AccountSharedModule,
    AvatarModule,
    UploadComponent,
    NoFutureValidator,
    NoBeforeValidator,
    FundingAppealsKpiComponent,
    ProgressBarComponent,
    FileBannerComponent
  ]
})
export class ComponentsModule {}
