/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConstructionSite } from './constructionSite';


export interface FundingAppeal { 
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    id?: string;
    num?: number;
    ConstructionSite?: ConstructionSite;
    numLabel?: FundingAppeal.NumLabelEnum;
    amount: number;
    amountInclTaxes?: number;
    billToDate?: string;
    participationPercent?: number;
    regulate?: number;
    isCumulatedAmountActive?: boolean;
    isQuotePart?: boolean;
    isClosing?: boolean;
    closingMode?: FundingAppeal.ClosingModeEnum;
    cumulatedSituationAmountExclTaxes?: number;
    cumulatedAmountExclTaxes?: number;
    percent: number;
    state?: FundingAppeal.StateEnum;
}
export namespace FundingAppeal {
    export type NumLabelEnum = 'FUNDING_APPEALS' | 'BILL_NUMBER' | 'ASSET';
    export const NumLabelEnum = {
        FundingAppeals: 'FUNDING_APPEALS' as NumLabelEnum,
        BillNumber: 'BILL_NUMBER' as NumLabelEnum,
        Asset: 'ASSET' as NumLabelEnum
    };
    export type ClosingModeEnum = 'MODE1' | 'MODE2';
    export const ClosingModeEnum = {
        Mode1: 'MODE1' as ClosingModeEnum,
        Mode2: 'MODE2' as ClosingModeEnum
    };
    export type StateEnum = 'PENDING' | 'PAID' | 'LATE' | 'EXPIRED';
    export const StateEnum = {
        Pending: 'PENDING' as StateEnum,
        Paid: 'PAID' as StateEnum,
        Late: 'LATE' as StateEnum,
        Expired: 'EXPIRED' as StateEnum
    };
}


