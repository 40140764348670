/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BillItemRequest { 
    description?: string;
    description2?: string;
    descriptionV2?: string;
    shortDescription?: string;
    quantity?: number;
    fundingAppealNum?: number;
    isDeduction?: boolean;
    unitPrice?: number;
    unitPriceHT?: number;
    participationExclTaxes?: number;
    isParticipationActive?: boolean;
    isRegulate?: boolean;
    amountExcludingDeductedLines?: number;
    totalPrice?: number;
    notPaidTotal?: number;
    totalDue?: number;
    vatAmount?: number;
    type?: string;
}

