import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { APP_EVENTS } from '../../../../shared/event/event.enum';
import { JhiEventManager } from 'ng-jhipster';
import {
  ConstructionSite,
  InvitationCommandResourceService,
  Invitations,
  Lot,
  LotMemberCommandResourceService,
  LotQueryResourceService,
  Member,
  MemberQueryResourceService
} from '../../../../shared/api';
import RoleEnum = Member.RoleEnum;

@Component({
  selector: 'fc-member-invitation-modal',
  templateUrl: './member-invitation-modal.component.html'
})
export class MemberInvitationModalComponent implements OnChanges {
  @Input() constructionSite: ConstructionSite;
  @Input() role: string;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  lot: Lot = { name: null, description: null, role: RoleEnum.Member };
  lots: Lot[];
  members: Member[];
  membersInvited: Member[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private memberQueryResourceService: MemberQueryResourceService,
    private lotMemberCommandResourceService: LotMemberCommandResourceService,
    private lotQueryResourceService: LotQueryResourceService,
    private invitationCommandResourceService: InvitationCommandResourceService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private eventManager: JhiEventManager
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.role && simpleChanges.role.currentValue !== simpleChanges.role.previousValue) {
      this.reload();
    }
  }

  sendInvitation(invitationWithLot: { invitations: Invitations; lotId?: string }) {
    invitationWithLot.invitations.invitations.forEach((invitation) => ((invitation.role as string) = this.role));
    invitationWithLot.invitations.organisation = this.constructionSite.client;

    this.invitationCommandResourceService
      .sendInvitations(this.constructionSite.id, invitationWithLot.invitations)
      .subscribe(
        () => {
          this.toasterService.success(
            this.translateService.instant('construction-site.components.form.messages.invitation-success')
          );
          this.changeMembers();
        },
        () => {
          this.toasterService.error(
            this.translateService.instant('construction-site.components.form.messages.invitation-error')
          );
          this.changeMembers();
        }
      );
  }

  changeMembers() {
    this.loadMembers();
    this.eventManager.broadcast(APP_EVENTS.CONSTRUCTION_SITE_UPDATED);
    this.eventManager.broadcast(APP_EVENTS.MEMBERS_SITE);
  }

  private reload() {
    if (this.constructionSite) {
      this.lotQueryResourceService.getLots(this.constructionSite.id, false).subscribe((lots) => (this.lots = lots));
      this.loadMembers();
    }
  }

  private loadMembers() {
    this.memberQueryResourceService
      .getMembers(this.constructionSite.id)
      .subscribe((members) => (this.members = members));
  }

  close() {
    this.closeModal.emit(true);
  }
}
