import { Component, OnInit } from '@angular/core';
import { SignatoryCommandResourceService } from '../../../../shared/api';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fc-signatory-redirection',
  templateUrl: './signatory-redirection.component.html',
  styleUrls: ['./signatory-redirection.component.scss']
})
export class SignatoryRedirectionComponent implements OnInit {
  constructionSiteId: string;
  memberId: string;
  lotId: string;
  shortId: string;
  key: string;

  constructor(
    private signatoryCommandResourceService: SignatoryCommandResourceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.memberId = params.memberId;
      this.lotId = params.lotId;
      this.constructionSiteId = params.siteId;
      this.shortId = params.id;
    });

    this.route.queryParams.subscribe((params) => {
      this.key = params.key;
    });

    if (this.shortId) {
      this.signatoryCommandResourceService.createShortSignatory(this.shortId).subscribe((url) => {
        window.location.href = url.url;
      });
    } else {
      this.signatoryCommandResourceService
        .createTransactionSignatory(this.constructionSiteId, this.lotId, this.memberId)
        .subscribe((url) => {
          window.location.href = url.url;
        });
    }
  }
}
