import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import FileSaver from 'file-saver';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const CONTENT_DISPOSITION_HEADER = 'content-disposition';
const FILENAME_PROPERTY = 'filename=';

@Injectable()
export class DownloadFileService {
  constructor(private http: HttpClient) {}

  download(
    url: string,
    filename?: string,
    acceptHeaders: string[] = ['*/*'],
    params?: HttpParams
  ): Observable<{ status: number; statusText: string }> {
    const headers = new HttpHeaders().set('Accept', acceptHeaders);

    if (location.host !== environment.host) {
      url = url.replace(environment.host, location.host);
    }

    return this.http.get(url, { observe: 'response', headers, responseType: 'blob', params }).pipe(
      map((response: HttpResponse<Blob>) => {
        const contentDisposition = (response.headers as HttpHeaders).get(CONTENT_DISPOSITION_HEADER);
        if (contentDisposition && !filename) {
          filename = contentDisposition.substring(
            contentDisposition.indexOf(FILENAME_PROPERTY) + FILENAME_PROPERTY.length
          );
        }
        if (!contentDisposition && !filename) {
          filename = url.split('/').pop();
        }
        // Download file using the file-saver library
        FileSaver.saveAs(response.body, filename);
        return { status: response.status, statusText: response.statusText };
      })
    );
  }

  /* istanbul ignore next */
  downloadBlob(
    url: string,
    filename?: string,
    acceptHeaders: string[] = ['*/*'],
    params?: HttpParams
  ): Observable<Blob> {
    const headers = new HttpHeaders().set('Accept', acceptHeaders);

    if (location.host !== environment.host) {
      url = url.replace(environment.host, location.host);
    }

    return this.http.get(url, { observe: 'response', headers, responseType: 'blob', params }).pipe(
      map((response: HttpResponse<Blob>) => {
        const contentDisposition = (response.headers as HttpHeaders).get(CONTENT_DISPOSITION_HEADER);
        if (contentDisposition && !filename) {
          filename = contentDisposition.substring(
            contentDisposition.indexOf(FILENAME_PROPERTY) + FILENAME_PROPERTY.length
          );
        }
        if (!contentDisposition && !filename) {
          filename = url.split('/').pop();
        }
        // Download file using the file-saver library
        return response.body;
      })
    );
  }

  downloadBinary(fileBuffer: ArrayBuffer, type: string, filename: string = 'document.pdf') {
    const file = new Blob([fileBuffer], { type });
    FileSaver.saveAs(file, filename);
  }
}
