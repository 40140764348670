import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from '../../features/account/account.service';
import { Account } from '../../features/account/shared/models/account';
import { Subscription } from 'rxjs';
import { JhiEventManager } from 'ng-jhipster';
import { APP_EVENTS } from '../../shared/event/event.enum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConstructionSiteInvitationModalComponent } from '../../features/construction-site/shared/components/invitation/construction-site-invitation-modal.component';
import { ConstructionSite, Member } from '../../shared/api';

@Component({
  selector: 'fc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  account: Account;
  constructionSite: ConstructionSite;
  subscriptions: Subscription[] = [];

  constructor(public accountService: AccountService, private event: JhiEventManager, private modalService: NgbModal) {}

  ngOnInit() {
    this.accountService.get(true).subscribe();
    this.subscriptions.push(
      this.event.subscribe(APP_EVENTS.CONSTRUCTION_SITE_SELECTED, (event) => (this.constructionSite = event.content))
    );
    this.subscriptions.push(this.accountService.events.subscribe((account) => (this.account = account)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  logout(event: Event) {
    event.preventDefault();
    this.accountService.logout();
  }

  showMembersModal(event: Event) {
    event.preventDefault();
    const modalRef = this.modalService.open(ConstructionSiteInvitationModalComponent, { windowClass: 'fullscreen' });
    modalRef.componentInstance.constructionSite = this.constructionSite;
  }

  userToMember() {
    if (this.account) {
      return [{ user: { lastName: this.account.lastName, firstName: this.account.firstName } } as Member];
    }
    return null;
  }
}
