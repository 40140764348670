/**
 * List of internal application events
 */
export enum APP_EVENTS {
  // When http error is catch
  HTTP_ERROR = 'event:http-error',

  // When user is disconnected
  USER_DISCONNECTED = 'event:user-disconnected',

  // When user identity is resolved
  IDENTITY_KNOWN = 'event:identity-known',

  // When user identity is not resolved
  IDENTITY_UNKNOWN = 'event:identity-unknown',

  // When account change
  ACCOUNT_CHANGED = 'event:account-changed',

  // When a construction site is selected
  CONSTRUCTION_SITE_SELECTED = 'event:construction-site-selected',

  CONSTRUCTION_SITE_UPDATE_CURRENT = 'event:construction-site-update-current',

  // When a construction site is updated
  CONSTRUCTION_SITE_UPDATED = 'event:construction-site-updated',

  // When a list of agreement is updated
  AGREEMENT_LIST_UPDATED = 'event:construction-site-updated',

  // When a construction site is added
  CONSTRUCTION_SITE_ADDED = 'event:construction-site-added',

  // When a funding appeals lot is updated
  FUNDING_APPEALS_LOT_UPDATED = 'event:funding-appeals-lot-updated',

  // When funding appeals items is updated
  FUNDING_APPEALS_KPI_UPDATED = 'event:funding-appeals-kpi-updated',
  EXPENSE_KPI_UPDATED = 'event:expense-kpi-updated',

  EXPENSES_UPDATED = 'event:expenses-updated',
  EXPENSE_UPDATED = 'event:expense-updated',

  EXPENSES_SCHEDULED_UPDATED = 'event:expenses-scheduled-updated',

  EXPENSE_SCHEDULED_UPDATED = 'event:expense-scheduled-updated',

  // When lot is added or updated
  LOT_SHOW_UPDATED = 'event:lot-show-updated',
  LOT_LIST_UPDATED = 'event:lot-list-updated',

  // funding appeal remind history refresh
  FA_HISTORY_REFRESH = 'event:fa-history-refresh',

  // construction site create process
  CALCULATE_SIGNATORIES = 'event:calculate_signatories',

  // construction site create process
  AGREEMENT_DETAIL = 'event:agreement_detail',

  // members added
  MEMBERS_SITE = 'event:members_site',

  // refresh one lot after action
  REFRESH_ONE_LOT = 'event:refresh_one_lot',

  // refresh one expense after action
  REFRESH_ONE_EXPENSE = 'event:refresh_one_expense',

  UPLOAD_REMOVE_FILE = 'event:remove_all_files',

  EXPENSES_BP_FILTERS_UPDATED = 'event:expense_bp_filters_updated'
}
