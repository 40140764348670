import { NgModule } from '@angular/core';
import { FullnamePipe } from './pipes/fullname.pipe';
import { UcWordsPipe } from 'ngx-pipes';

@NgModule({
  declarations: [FullnamePipe],
  exports: [FullnamePipe],
  providers: [FullnamePipe, UcWordsPipe]
})
/**
 * Contains account related components and directives
 */
export class AccountSharedModule {}
