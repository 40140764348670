import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { PUBLIC_ROUTE } from './public.route';
import { CancelFormComponent } from './cancel/cancel-form.component';

@NgModule({
  imports: [SharedModule, RouterModule.forChild(PUBLIC_ROUTE)],
  exports: [CancelFormComponent],
  declarations: [CancelFormComponent],
  providers: [NgbActiveModal]
})
export class PublicModule {}
