import { NgModule } from '@angular/core';
import { EmailsComponent } from './emails/emails.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ValdemortModule } from 'ngx-valdemort';
import { FormsModule } from '@angular/forms';
import { EmailSelectComponent } from './email-select/email-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AvatarModule } from '../../shared/components/avatar/avatar.module';

@NgModule({
  imports: [TranslateModule, CommonModule, ValdemortModule, FormsModule, NgSelectModule, AvatarModule],
  exports: [EmailsComponent, EmailSelectComponent],
  declarations: [EmailsComponent, EmailSelectComponent],
  providers: []
})
export class EmailsModule {}
