export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

export const XSRF_TOKEN = 'XSRF-TOKEN';
export const XSRF_TOKEN_HEADER = `X-${XSRF_TOKEN}`;

export const API_ENDPOINTS = {
  gateway: 'api',
  user: '/services/user',
  construction: '/services/construction',
  public: '/services/public/construction'
};

/**
 * Created by node/webpack by reading the git describe command
 * {@see extra-webpack.config.js}
 */
export const FRONT_VERSION = {
  buildDate: new Date(parseInt(BUILD_TIMESTAMP, 10)).toLocaleString(),
  commit: process.env.GIT_COMMITHASH,
  branch: process.env.GIT_BRANCH
};

export const PDF_MIME_TYPES = ['application/pdf'];
export const ZIP_MIME_TYPES = ['application/zip'];
export const XLSX_MIME_TYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
export const XML_MIME_TYPES = ['application/xml'];
export const CSV_MIME_TYPES = ['text/CSV'];
export const INF_MIME_TYPES = ['text/plain'];
export const BP_FILENAME = 'export_bp.xlsx';
export const PROOF_FILENAME = 'Preuve.pdf';
export const EXPORT_FILENAME = 'Export - Compte Prorata #sitename#.xlsx';
export const EXPORT_FILENAME_V2 = 'Export - Compte Prorata.xlsx';
export const EXPORT_ONAYA_XML_FILENAME = 'Export Onaya - Compte Prorata.xml';
export const EXPORT_SAGE_FILENAME = 'Export SAGE - Compte Prorata #sitename#.xlsx';
export const EXPORT_CSV_FILENAME = 'faircost.csv';
export const EXPORT_INF_FILENAME = 'faircost.inf';
export const SUMMARY_FILENAME_ZIP = 'Liste des dépenses réalisées - Compte Prorata #sitename#.zip';
export const SUMMARY_FILENAME_XLSX = 'Liste des dépenses réalisées - Compte Prorata #sitename#.xlsx';
export const BUDGET_FILENAME_XLSX = 'Budget dépenses - Compte Prorata #sitename#.xlsx';
export const SUMMARY_SCHEDULED_FILENAME_XLSX = 'Liste des dépenses programmées - Compte Prorata #sitename#.xlsx';
export const SIGNATORY_FILENAME = 'Signature.pdf';
export const AGREEMENT_FILENAME = 'Convention.pdf';
export const FUNDING_APPEAL_BILL = 'Facture.pdf';
export const REPORT_FILENAME = 'Rapport.pdf';
export const REGISTERED_MAIL_NAME = 'Courrier recommande.pdf';
export const MS_WORD_MIME_TYPES = [
  // Word and OpenOffice document
  'application/msword',
  'application/rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text'
];

export const ZIP_ARCHIVE_DEFAULT_NAME = 'Export archive - Compte Prorata #sitename#.zip';

export const BINARY_MIME_TYPES = [
  ...MS_WORD_MIME_TYPES,
  // Excel and OpenOffice spreadsheet
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  // PDF
  ...PDF_MIME_TYPES
];

export const EMAILS_COMMA_REGEX = '^([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]{1,20},?)+$';
export const EXTENSION_REGEX = '[^.]+$';

export const CRISP_METHODS = {
  set: 'set',
  do: 'do',
  on: 'on',
  off: 'off'
};

export const CRISP_ACTIONS = {
  MESSAGE_TEXT: 'message:text',
  SESSION_DATA: 'session:data',
  SESSION_EVENT: 'session:event',
  USER_EMAIL: 'user:email',
  USER_PHONE: 'user:phone',
  USER_NICKNAME: 'user:nickname',
  USER_AVATAR: 'user:avatar',
  USER_COMPANY: 'user:company'
};

export const BILL_MODELS = [
  {
    name: 'model1',
    file: '../../../../assets/images/models/model1.png'
  },
  {
    name: 'model2',
    file: '../../../../assets/images/models/model2.png'
  },
  {
    name: 'model3',
    file: '../../../../assets/images/models/model3.png'
  },
  {
    name: 'model4',
    file: '../../../../assets/images/models/model4.png'
  },
  {
    name: 'model5',
    file: '../../../../assets/images/models/model5.png'
  },
  {
    name: 'model6',
    file: '../../../../assets/images/models/model6.png'
  },
  {
    name: 'model7',
    file: '../../../../assets/images/models/model7.png'
  },
  {
    name: 'model8',
    file: '../../../../assets/images/models/model8.png'
  },
  {
    name: 'model9',
    file: '../../../../assets/images/models/model9.png'
  },
  {
    name: 'model10',
    file: '../../../../assets/images/models/model10.png'
  },
  {
    name: 'model11',
    file: '../../../../assets/images/models/model11.png'
  },
  {
    name: 'model12',
    file: '../../../../assets/images/models/model12.png'
  }
];

export const CATEGORIES = [
  {
    id: 1,
    property: 'siteObservationSecurity',
    type: 1
  },
  {
    id: 2,
    property: 'energyConsumptionSubscriptions',
    type: 1
  },
  {
    id: 3,
    property: 'circulationCommonAccessSignage',
    type: 1
  },
  {
    id: 4,
    property: 'baseLifeMaintenanceSupplies',
    type: 1
  },
  {
    id: 5,
    property: 'maintenanceTemporaryNetworks',
    type: 1
  },
  {
    id: 7,
    property: 'exceptionalCleaning',
    type: 2
  },
  {
    id: 8,
    property: 'jointLiftingSupplies',
    type: 2
  },
  {
    id: 9,
    property: 'dumpstersSortingRentalEvacuation',
    type: 2
  },
  {
    id: 10,
    property: 'others',
    type: 2
  }
];

export const LIMIT_SITES_TITLE_GUIDE = 'Limite du nombre de chantiers actifs atteinte';

export const LIMIT_SITES_GUIDE =
  '      <div>Vous avez atteint le nombre maximal de chantiers actifs permis par votre abonnement annuel.</div><br>\n' +
  '      <div>Vous avez souscrit à une formule d’abonnement annuel vous permettant de disposer d’un maximum de #limit# chantiers actifs.' +
  '</div><br>\n' +
  '      <div>Cette limite ne tient pas compte des chantiers archivés et des chantiers en cours de clôture (chantier dont la ' +
  'facturation solde a été émise).</div><br>\n' +
  '      <div>Pour augmenter cette limite, vous pouvez contacter le service commercial par email à l’adresse email : ' +
  '<A href="thomas@thefaircost.com">thomas@thefaircost.com</A> ou par téléphone au <a href="tel:0663269477">06 63 26 94 77</a>.</div><br>\n' +
  '      <div class="text-right">Merci de votre compréhension, l’équipe FairCost</div>\n';
