/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

  import { FundingAppealRemindRequest } from '../model/models';
  import { Invitations } from '../model/models';
  import { RequestPartSignedDocument } from '../model/models';
  import { Signatory } from '../model/models';
  import { SignatoryCancelRequest } from '../model/models';
  import { SignatoryTransactionUrl } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



    @Injectable({
    providedIn: 'root'
    })
    export class SignatoryCommandResourceService {

  protected basePath = 'https://dev.thefaircost.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
  if (configuration) {
  this.configuration = configuration;
  }
  if (typeof this.configuration.basePath !== 'string') {
  if (typeof basePath !== 'string') {
  basePath = this.basePath;
  }
  this.configuration.basePath = basePath;
  }
  this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

    /**
    * @param consumes string[] mime-types
    * @return true: consumes contains 'multipart/form-data', false: otherwise
    */
    private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
    if (form === consume) {
    return true;
    }
    }
    return false;
    }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
  if (typeof value === "object" && value instanceof Date === false) {
  httpParams = this.addToHttpParamsRecursive(httpParams, value);
  } else {
  httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
  }
  return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
  if (value == null) {
  return httpParams;
  }

  if (typeof value === "object") {
    if (Array.isArray(value)) {
    (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
    } else if (value instanceof Date) {
    if (key != null) {
    httpParams = httpParams.append(key,
    (value as Date).toISOString().substr(0, 10));
    } else {
    throw Error("key may not be null if value is Date");
    }
            } else {
    Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
    httpParams, value[k], key != null ? `${key}.${k}` : k));
    }
  } else if (key != null) {
  httpParams = httpParams.append(key, value);
  } else {
  throw Error("key may not be null if value is not object or array");
  }
  return httpParams;
  }

    /**
        * @param constructionSiteId 
        * @param memberId 
        * @param signatoryCancelRequest 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public cancelSignatory(constructionSiteId: string, memberId: string, signatoryCancelRequest: SignatoryCancelRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public cancelSignatory(constructionSiteId: string, memberId: string, signatoryCancelRequest: SignatoryCancelRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public cancelSignatory(constructionSiteId: string, memberId: string, signatoryCancelRequest: SignatoryCancelRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public cancelSignatory(constructionSiteId: string, memberId: string, signatoryCancelRequest: SignatoryCancelRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling cancelSignatory.');
        }
        if (memberId === null || memberId === undefined) {
        throw new Error('Required parameter memberId was null or undefined when calling cancelSignatory.');
        }
        if (signatoryCancelRequest === null || signatoryCancelRequest === undefined) {
        throw new Error('Required parameter signatoryCancelRequest was null or undefined when calling cancelSignatory.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
    headers = headers.set('Content-Type', httpContentTypeSelected);
    }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/member/${encodeURIComponent(String(memberId))}/transaction/cancel`,
    signatoryCancelRequest,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param signer 
        * @param status 
        * @param id 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public changeTransactionState(signer: string, status: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public changeTransactionState(signer: string, status: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public changeTransactionState(signer: string, status: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public changeTransactionState(signer: string, status: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (signer === null || signer === undefined) {
        throw new Error('Required parameter signer was null or undefined when calling changeTransactionState.');
        }
        if (status === null || status === undefined) {
        throw new Error('Required parameter status was null or undefined when calling changeTransactionState.');
        }
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling changeTransactionState.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
            if (signer !== undefined && signer !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>signer, 'signer');
            }
            if (status !== undefined && status !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>status, 'status');
            }
            if (id !== undefined && id !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>id, 'id');
            }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/services/invitation/construction/universign`,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param id 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public createShortSignatory(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<SignatoryTransactionUrl>;
    public createShortSignatory(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<SignatoryTransactionUrl>>;
    public createShortSignatory(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<SignatoryTransactionUrl>>;
    public createShortSignatory(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling createShortSignatory.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<SignatoryTransactionUrl>(`${this.configuration.basePath}/services/construction/construction-sites/signatory/${encodeURIComponent(String(id))}`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param lotId 
        * @param memberId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public createTransactionSignatory(constructionSiteId: string, lotId: string, memberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<SignatoryTransactionUrl>;
    public createTransactionSignatory(constructionSiteId: string, lotId: string, memberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<SignatoryTransactionUrl>>;
    public createTransactionSignatory(constructionSiteId: string, lotId: string, memberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<SignatoryTransactionUrl>>;
    public createTransactionSignatory(constructionSiteId: string, lotId: string, memberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling createTransactionSignatory.');
        }
        if (lotId === null || lotId === undefined) {
        throw new Error('Required parameter lotId was null or undefined when calling createTransactionSignatory.');
        }
        if (memberId === null || memberId === undefined) {
        throw new Error('Required parameter memberId was null or undefined when calling createTransactionSignatory.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<SignatoryTransactionUrl>(`${this.configuration.basePath}/services/invitation/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/lot/${encodeURIComponent(String(lotId))}/member/${encodeURIComponent(String(memberId))}/signatory`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param lotId 
        * @param memberId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public initShortSignLink(constructionSiteId: string, lotId: string, memberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<SignatoryTransactionUrl>;
    public initShortSignLink(constructionSiteId: string, lotId: string, memberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<SignatoryTransactionUrl>>;
    public initShortSignLink(constructionSiteId: string, lotId: string, memberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<SignatoryTransactionUrl>>;
    public initShortSignLink(constructionSiteId: string, lotId: string, memberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling initShortSignLink.');
        }
        if (lotId === null || lotId === undefined) {
        throw new Error('Required parameter lotId was null or undefined when calling initShortSignLink.');
        }
        if (memberId === null || memberId === undefined) {
        throw new Error('Required parameter memberId was null or undefined when calling initShortSignLink.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<SignatoryTransactionUrl>(`${this.configuration.basePath}/services/invitation/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/lot/${encodeURIComponent(String(lotId))}/member/${encodeURIComponent(String(memberId))}/init-link`,
    null,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param fundingAppealRemindRequest 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public remindAllLatestAgreementSignatories(constructionSiteId: string, fundingAppealRemindRequest?: FundingAppealRemindRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public remindAllLatestAgreementSignatories(constructionSiteId: string, fundingAppealRemindRequest?: FundingAppealRemindRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public remindAllLatestAgreementSignatories(constructionSiteId: string, fundingAppealRemindRequest?: FundingAppealRemindRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public remindAllLatestAgreementSignatories(constructionSiteId: string, fundingAppealRemindRequest?: FundingAppealRemindRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling remindAllLatestAgreementSignatories.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
    headers = headers.set('Content-Type', httpContentTypeSelected);
    }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/latest/signatories/remind`,
    fundingAppealRemindRequest,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param signatoryId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public remindLatestAgreementSignatory(constructionSiteId: string, signatoryId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Signatory>;
    public remindLatestAgreementSignatory(constructionSiteId: string, signatoryId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Signatory>>;
    public remindLatestAgreementSignatory(constructionSiteId: string, signatoryId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Signatory>>;
    public remindLatestAgreementSignatory(constructionSiteId: string, signatoryId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling remindLatestAgreementSignatory.');
        }
        if (signatoryId === null || signatoryId === undefined) {
        throw new Error('Required parameter signatoryId was null or undefined when calling remindLatestAgreementSignatory.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<Signatory>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/latest/signatories/${encodeURIComponent(String(signatoryId))}/remind`,
    null,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param version 
        * @param document 
        * @param signedDocumentPart 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public saveSignedDocument(constructionSiteId: string, version: number, document?: Blob, signedDocumentPart?: RequestPartSignedDocument, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public saveSignedDocument(constructionSiteId: string, version: number, document?: Blob, signedDocumentPart?: RequestPartSignedDocument, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public saveSignedDocument(constructionSiteId: string, version: number, document?: Blob, signedDocumentPart?: RequestPartSignedDocument, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public saveSignedDocument(constructionSiteId: string, version: number, document?: Blob, signedDocumentPart?: RequestPartSignedDocument, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling saveSignedDocument.');
        }
        if (version === null || version === undefined) {
        throw new Error('Required parameter version was null or undefined when calling saveSignedDocument.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

      const canConsumeForm = this.canConsumeForm(consumes);

      let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
      formParams = new FormData();
      } else {
      formParams = new HttpParams({encoder: this.encoder});
      }

          if (document !== undefined) {
          formParams = formParams.append('document', <any>document) as any || formParams;
        }
          if (signedDocumentPart !== undefined) {
          formParams = formParams.append('signedDocumentPart', useForm ? new Blob([JSON.stringify(signedDocumentPart)], {type: 'application/json'}) : <any>signedDocumentPart) as any || formParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/${encodeURIComponent(String(version))}/document`,
    convertFormParamsToString ? formParams.toString() : formParams,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param invitations 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public sendSignatoryInvitations(constructionSiteId: string, invitations: Invitations, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public sendSignatoryInvitations(constructionSiteId: string, invitations: Invitations, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public sendSignatoryInvitations(constructionSiteId: string, invitations: Invitations, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public sendSignatoryInvitations(constructionSiteId: string, invitations: Invitations, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling sendSignatoryInvitations.');
        }
        if (invitations === null || invitations === undefined) {
        throw new Error('Required parameter invitations was null or undefined when calling sendSignatoryInvitations.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
    headers = headers.set('Content-Type', httpContentTypeSelected);
    }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/latest/invitations`,
    invitations,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param version 
        * @param document 
        * @param signedDocumentPart 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public updateSignedDocument(constructionSiteId: string, version: number, document?: Blob, signedDocumentPart?: RequestPartSignedDocument, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateSignedDocument(constructionSiteId: string, version: number, document?: Blob, signedDocumentPart?: RequestPartSignedDocument, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateSignedDocument(constructionSiteId: string, version: number, document?: Blob, signedDocumentPart?: RequestPartSignedDocument, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateSignedDocument(constructionSiteId: string, version: number, document?: Blob, signedDocumentPart?: RequestPartSignedDocument, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling updateSignedDocument.');
        }
        if (version === null || version === undefined) {
        throw new Error('Required parameter version was null or undefined when calling updateSignedDocument.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

      const canConsumeForm = this.canConsumeForm(consumes);

      let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
      formParams = new FormData();
      } else {
      formParams = new HttpParams({encoder: this.encoder});
      }

          if (document !== undefined) {
          formParams = formParams.append('document', <any>document) as any || formParams;
        }
          if (signedDocumentPart !== undefined) {
          formParams = formParams.append('signedDocumentPart', useForm ? new Blob([JSON.stringify(signedDocumentPart)], {type: 'application/json'}) : <any>signedDocumentPart) as any || formParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/${encodeURIComponent(String(version))}/document`,
    convertFormParamsToString ? formParams.toString() : formParams,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

}
