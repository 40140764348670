import { Pipe, PipeTransform } from '@angular/core';
import { UcWordsPipe } from 'ngx-pipes';

@Pipe({ name: 'fullname', pure: false })
export class FullnamePipe implements PipeTransform {
  constructor(private ucWordsPipe: UcWordsPipe) {}
  transform(user: { firstName?: string; lastName?: string }, options?: { default: string }): string {
    let formattedUser;

    if (!user || (!user.firstName && !user.lastName)) {
      return options && options.default ? options.default : '';
    }

    if (!user.firstName) {
      formattedUser = user.lastName;
    } else if (!user.lastName) {
      formattedUser = user.firstName;
    } else {
      formattedUser = `${user.firstName} ${user.lastName}`;
    }

    return this.ucWordsPipe.transform(formattedUser);
  }
}
