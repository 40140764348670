import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AuthorityEnum } from '../../../account/shared/models/authority.enum';
import { AbstractControlValueAccessor } from '../../../../shared/components/form/abstract-control-value-accessor';
import { ControlContainer, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
  selector: 'fc-member-select-role',
  templateUrl: './member-select-role.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MemberSelectRoleComponent),
      multi: true
    }
  ],
  // Attach the current select to the parent form
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class MemberSelectRoleComponent extends AbstractControlValueAccessor<string> implements OnInit {
  @Input() roles: AuthorityEnum[];
  @Input() config: { id?: string; required?: boolean; disabled?: boolean };
  @Output() changeRole: EventEmitter<AuthorityEnum> = new EventEmitter<AuthorityEnum>();
  constructor() {
    super();
    this.config = {};
  }

  ngOnInit() {
    if (!this.config.id) {
      this.config.id = 'roleAuthority';
    }
  }

  selectRole(role) {
    this.changeRole.emit(role);
  }
}
