/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

  import { Expense } from '../model/models';
  import { InterCompanyExpense } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



    @Injectable({
    providedIn: 'root'
    })
    export class ExpenseQueryResourceService {

  protected basePath = 'https://dev.thefaircost.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
  if (configuration) {
  this.configuration = configuration;
  }
  if (typeof this.configuration.basePath !== 'string') {
  if (typeof basePath !== 'string') {
  basePath = this.basePath;
  }
  this.configuration.basePath = basePath;
  }
  this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
  if (typeof value === "object" && value instanceof Date === false) {
  httpParams = this.addToHttpParamsRecursive(httpParams, value);
  } else {
  httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
  }
  return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
  if (value == null) {
  return httpParams;
  }

  if (typeof value === "object") {
    if (Array.isArray(value)) {
    (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
    } else if (value instanceof Date) {
    if (key != null) {
    httpParams = httpParams.append(key,
    (value as Date).toISOString().substr(0, 10));
    } else {
    throw Error("key may not be null if value is Date");
    }
            } else {
    Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
    httpParams, value[k], key != null ? `${key}.${k}` : k));
    }
  } else if (key != null) {
  httpParams = httpParams.append(key, value);
  } else {
  throw Error("key may not be null if value is not object or array");
  }
  return httpParams;
  }

    /**
        * @param constructionSiteId 
        * @param term 
        * @param categoryId 
        * @param startDate 
        * @param endDate 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getBudgetExport(constructionSiteId: string, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<Array<string>>;
    public getBudgetExport(constructionSiteId: string, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<HttpResponse<Array<string>>>;
    public getBudgetExport(constructionSiteId: string, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<HttpEvent<Array<string>>>;
    public getBudgetExport(constructionSiteId: string, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getBudgetExport.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
            if (term !== undefined && term !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>term, 'term');
            }
            if (categoryId !== undefined && categoryId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>categoryId, 'category-id');
            }
            if (startDate !== undefined && startDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>startDate, 'start-date');
            }
            if (endDate !== undefined && endDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>endDate, 'end-date');
            }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/expenses/export/budget`,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param expenseId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getExpense(constructionSiteId: string, expenseId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Expense>;
    public getExpense(constructionSiteId: string, expenseId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Expense>>;
    public getExpense(constructionSiteId: string, expenseId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Expense>>;
    public getExpense(constructionSiteId: string, expenseId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getExpense.');
        }
        if (expenseId === null || expenseId === undefined) {
        throw new Error('Required parameter expenseId was null or undefined when calling getExpense.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Expense>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/expenses/${encodeURIComponent(String(expenseId))}`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param active 
        * @param fromDate 
        * @param toDate 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getExpenses(constructionSiteId: string, active: boolean, fromDate?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<Expense>>;
    public getExpenses(constructionSiteId: string, active: boolean, fromDate?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<Expense>>>;
    public getExpenses(constructionSiteId: string, active: boolean, fromDate?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<Expense>>>;
    public getExpenses(constructionSiteId: string, active: boolean, fromDate?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getExpenses.');
        }
        if (active === null || active === undefined) {
        throw new Error('Required parameter active was null or undefined when calling getExpenses.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
            if (active !== undefined && active !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>active, 'active');
            }
            if (fromDate !== undefined && fromDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>fromDate, 'from-date');
            }
            if (toDate !== undefined && toDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>toDate, 'to-date');
            }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<Expense>>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/expenses`,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getInterCompany(constructionSiteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<InterCompanyExpense>>;
    public getInterCompany(constructionSiteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<InterCompanyExpense>>>;
    public getInterCompany(constructionSiteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<InterCompanyExpense>>>;
    public getInterCompany(constructionSiteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getInterCompany.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<InterCompanyExpense>>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/expenses/inter-company`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param active 
        * @param term 
        * @param categoryId 
        * @param startDate 
        * @param endDate 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getSummaryXLSX(constructionSiteId: string, active: boolean, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<Array<string>>;
    public getSummaryXLSX(constructionSiteId: string, active: boolean, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<HttpResponse<Array<string>>>;
    public getSummaryXLSX(constructionSiteId: string, active: boolean, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<HttpEvent<Array<string>>>;
    public getSummaryXLSX(constructionSiteId: string, active: boolean, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getSummaryXLSX.');
        }
        if (active === null || active === undefined) {
        throw new Error('Required parameter active was null or undefined when calling getSummaryXLSX.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
            if (active !== undefined && active !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>active, 'active');
            }
            if (term !== undefined && term !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>term, 'term');
            }
            if (categoryId !== undefined && categoryId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>categoryId, 'category-id');
            }
            if (startDate !== undefined && startDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>startDate, 'start-date');
            }
            if (endDate !== undefined && endDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>endDate, 'end-date');
            }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/expenses/summary/xlsx`,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param active 
        * @param term 
        * @param categoryId 
        * @param startDate 
        * @param endDate 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getSummaryZip(constructionSiteId: string, active: boolean, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public getSummaryZip(constructionSiteId: string, active: boolean, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public getSummaryZip(constructionSiteId: string, active: boolean, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public getSummaryZip(constructionSiteId: string, active: boolean, term?: string, categoryId?: number, startDate?: string, endDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getSummaryZip.');
        }
        if (active === null || active === undefined) {
        throw new Error('Required parameter active was null or undefined when calling getSummaryZip.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
            if (active !== undefined && active !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>active, 'active');
            }
            if (term !== undefined && term !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>term, 'term');
            }
            if (categoryId !== undefined && categoryId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>categoryId, 'category-id');
            }
            if (startDate !== undefined && startDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>startDate, 'start-date');
            }
            if (endDate !== undefined && endDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>endDate, 'end-date');
            }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/expenses/summary/pdf`,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public importKalitics(constructionSiteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<Expense>>;
    public importKalitics(constructionSiteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<Expense>>>;
    public importKalitics(constructionSiteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<Expense>>>;
    public importKalitics(constructionSiteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling importKalitics.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<Expense>>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/kalitics/expenses`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

}
