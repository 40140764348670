import { Component, OnInit } from '@angular/core';
import { MemberInvitation } from '../shared/models/member-invitation';
import { AuthorityEnum } from '../../account/shared/models/authority.enum';
import { ManagerOfPipe } from '../../../shared/pipes/manager-of.pipe';

import {
  ConstructionSiteQueryResourceService,
  Invitation,
  InvitationCommandResourceService,
  Invitations,
  LotMemberCommandResourceService
} from '../../../shared/api';
import { EMAILS_COMMA_REGEX } from '../../../app.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fc-member-invite',
  templateUrl: './member-invite.component.html',
  styleUrls: ['./member-invite.component.scss'],
  providers: [ManagerOfPipe]
})
export class MemberInviteComponent implements OnInit {
  emails: string;
  memberInvitation: MemberInvitation;
  emailsRegex = EMAILS_COMMA_REGEX;
  regex = new RegExp(this.emailsRegex);
  constructionSiteId: string;
  lotId: string;
  clientId: string;
  page: string;

  constructor(
    private lotMemberCommandResourceService: LotMemberCommandResourceService,
    private invitationCommandResourceService: InvitationCommandResourceService,
    private constructionSiteQueryResourceService: ConstructionSiteQueryResourceService,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.memberInvitation = {};
    this.route.params.subscribe((params) => {
      this.constructionSiteId = params.siteId;
      this.lotId = params.lotId;
      this.clientId = params.clientId;
    });
    this.route.queryParams.subscribe((queryParams) => {
      this.page = queryParams.page;
    });
  }

  sendLotInvitations() {
    const invitations: Invitations = { invitations: null };
    invitations.invitations = this.memberInvitation.emails.map((email) => {
      const invitation: Invitation = { email: null };
      invitation.email = email.email;
      invitation.role = AuthorityEnum.ROLE_MEMBER;
      invitation.hasToSendInvitation = email.hasToSendInvitation;
      return invitation;
    });

    this.lotMemberCommandResourceService
      .sendOfflineLotInvitations(this.constructionSiteId, this.lotId, invitations)
      .subscribe(
        () => {
          this.router.navigate(['confirmation'], { relativeTo: this.route });
          this.toasterService.success(
            this.translateService.instant('construction-site.components.form.messages.invitation-success')
          );
        },
        () =>
          this.toasterService.error(
            this.translateService.instant('construction-site.components.form.messages.invitation-error')
          )
      );

    this.memberInvitation.emails = [];
    this.emails = '';
  }

  sendClientInvitations() {
    const invitations: Invitations = { invitations: null };
    invitations.invitations = this.memberInvitation.emails.map((email) => {
      const invitation: Invitation = { email: null };
      invitation.email = email.email;
      invitation.role = AuthorityEnum.ROLE_GENERAL_CONTRACTOR;
      invitation.hasToSendInvitation = email.hasToSendInvitation;
      return invitation;
    });

    this.invitationCommandResourceService.sendInvitations(this.constructionSiteId, invitations).subscribe(
      () => {
        this.toasterService.success(
          this.translateService.instant('construction-site.components.form.messages.invitation-success')
        );
        this.router.navigate(['/']);
      },
      () => {
        this.toasterService.error(
          this.translateService.instant('construction-site.components.form.messages.invitation-error')
        );
      }
    );
  }

  sendInvitations() {
    if (this.lotId) {
      this.sendLotInvitations();
    } else {
      this.sendClientInvitations();
    }
  }

  setInvitation() {
    this.memberInvitation.emails = this.emails.split(',').map((email) => {
      return {
        email,
        hasToSendInvitation: true
      };
    });
  }
}
