import { NgModule } from '@angular/core';
import { MemberRowComponent } from './components/row/member-row.component';
import { AvatarModule } from '../../../shared/components/avatar/avatar.module';
import { AccountSharedModule } from '../../account/shared/account-shared.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  exports: [MemberRowComponent],
  imports: [AvatarModule, AccountSharedModule, CommonModule, TranslateModule],
  declarations: [MemberRowComponent]
})
export class MemberSharedModule {}
