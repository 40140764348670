import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MemberListComponent } from './list/member-list.component';
import { NgbActiveModal, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { MemberSelectRoleComponent } from './components/select/member-select-role.component';
import { MemberSharedModule } from './shared/member-shared.module';
import { MemberInvitationModalComponent } from './components/modal/member-invitation-modal.component';
import { EmailsModule } from '../../core/emails/emails.module';
import { MemberInviteComponent } from './invite/member-invite.component';
import { RouterModule } from '@angular/router';
import { MEMBER_ROUTES } from './member.route';
import { ConfirmationInvitationComponent } from './components/confirmation/confirmation-invitation.component';
import { SignatoryRedirectionComponent } from './components/loading/signatory-redirection.component';

@NgModule({
  imports: [
    MemberSharedModule,
    SharedModule,
    NgSelectModule,
    NgbModalModule,
    NgbDropdownModule,
    EmailsModule,
    RouterModule.forChild(MEMBER_ROUTES)
  ],
  exports: [
    MemberSharedModule,
    MemberListComponent,
    MemberSelectRoleComponent,
    NgbDropdownModule,
    MemberInvitationModalComponent,
    MemberInviteComponent,
    ConfirmationInvitationComponent
  ],
  declarations: [
    MemberListComponent,
    MemberSelectRoleComponent,
    MemberInvitationModalComponent,
    MemberInviteComponent,
    ConfirmationInvitationComponent,
    SignatoryRedirectionComponent
  ],
  providers: [NgbActiveModal]
})
export class MemberModule {}
