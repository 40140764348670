import { Injectable, Optional } from '@angular/core';
import { API_ENDPOINTS } from '../../app.constants';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Account } from './shared/models/account';
import { catchError, map } from 'rxjs/operators';
import { AuthorityEnum } from './shared/models/authority.enum';
import { AccountResourceService, Configuration, Member, MemberId } from '../../shared/api';

@Injectable()
export class AccountService extends AccountResourceService {
  events = new Subject<Account>();
  private currentAccount: Account;
  private authenticated = false;

  constructor(http: HttpClient, @Optional() configuration: Configuration) {
    super(http, '', configuration);
  }

  get account(): Account {
    return this.currentAccount;
  }

  getRoleIn(entity: { members: Member[] | MemberId[] }): string {
    if (!entity || !entity.members) {
      return null;
    }

    const member = entity.members.find((m) => this.account.login === m.login);
    return member ? member.role : null;
  }

  isManagerOf(entity: { members: Member[] | MemberId[]; siren: string }): boolean {
    if (!this.account) {
      return false;
    }

    /* istanbul ignore next */
    if (this.account?.admin && this.account.organisation.identity.parentRegistrationId === entity?.siren) {
      return true;
    }

    if (!entity || !entity.members) {
      return false;
    }

    return entity.members.some(
      (member) => this.account.login === member.login && member.role === AuthorityEnum.ROLE_MANAGER
    );
  }

  isManagerOfWithAccount(entity: { members: Member[] | MemberId[]; siren: string }, account: Account): boolean {
    if (!account) {
      return false;
    }

    /* istanbul ignore next */
    if (account?.admin && account.organisation.identity.parentRegistrationId === entity?.siren) {
      return true;
    }

    if (!entity || !entity.members) {
      return false;
    }

    return entity.members.some(
      (member) => this.account.login === member.login && member.role === AuthorityEnum.ROLE_MANAGER
    );
  }

  isNotMemberOf(entity: { members: Member[] | MemberId[] }): boolean {
    if (!entity || !entity.members) {
      return false;
    }

    return (
      entity &&
      entity.members &&
      entity.members
        .filter((member) => member.login === this.account.login)
        .every((member) => member.role !== AuthorityEnum.ROLE_MEMBER)
    );
  }

  isMemberOf(entity: { members: Member[] | MemberId[] }): boolean {
    if (!entity || !entity.members) {
      return false;
    }

    return entity.members.some((member) => this.account.login === member.login);
  }

  hasAuthorities(authorities: string[]): boolean {
    if (!this.authenticated || !this.currentAccount || !this.currentAccount.authorities) {
      return false;
    }

    return (
      authorities &&
      authorities.length &&
      authorities.some((a) => Array.from(this.currentAccount.authorities).includes(a))
    );
  }

  get(force = false): Observable<Account> {
    if (force) {
      this.currentAccount = null;
    }

    if (this.currentAccount) {
      return of(this.currentAccount);
    }

    return this.getAccount('body', false, { httpHeaderAccept: 'application/json' } as any).pipe(
      map((account) => {
        if (account) {
          this.currentAccount = account;
          this.authenticated = true;

          this.events.next(account);
        } else {
          this.clear(false);
        }
        return this.currentAccount;
      }),
      catchError((error) => {
        this.clear();
        return throwError(error);
      })
    );
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  /**
   * Open login page
   */
  login(redirect_uri = null) {
    location.href = '/login' + (redirect_uri ? '?target_url=' + redirect_uri : '');
  }

  /**
   * Ajax call of the gateway logout API
   * Useful to log out a user without any http redirect
   */
  apiLogout(): Observable<void> {
    return this.httpClient.post<void>(`/${API_ENDPOINTS.gateway}/logout`, {});
  }

  /**
   * Invalids current identity and redirect to logout page
   */
  logout(redirect_uri = null, redirect = true) {
    this.clear();
    if (redirect) {
      location.href = '/logout' + (redirect_uri ? '?target_url=' + redirect_uri : '');
    } else {
      this.apiLogout().subscribe();
    }
  }

  isCompletedProfile(): boolean {
    return Boolean(
      this.currentAccount &&
        this.currentAccount.organisation &&
        this.currentAccount.organisation.identity &&
        this.currentAccount.organisation.identity.registrationId &&
        this.currentAccount.organisation.identity.name &&
        this.currentAccount.organisation.phone
    );
  }

  isPendingProfile() {
    return Boolean(this.currentAccount && this.currentAccount.pending);
  }

  isAdmin() {
    return Boolean(this.currentAccount && Array.from(this.currentAccount.authorities).includes('ROLE_ADMIN'));
  }

  private clear(dispatch = true) {
    this.currentAccount = null;
    this.authenticated = false;

    if (dispatch) {
      this.events.next(this.currentAccount);
    }
  }
}
