import { Injectable } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CRISP_ACTIONS, CRISP_METHODS } from '../../app.constants';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Account } from '../../features/account/shared/models/account';

@Injectable({
  providedIn: 'root'
})
export class CrispService {
  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  initTabSubscription() {
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((currentRoute) => {
            while (currentRoute.firstChild) {
              currentRoute = currentRoute.firstChild;
            }
            return currentRoute;
          }),
          mergeMap((currentRoute) => currentRoute.data),
          filter((data) => data && data.crisp)
        )
        .subscribe((routeData) => {
          // Call crisp script
          window['$crisp'].push([
            CRISP_METHODS.set,
            CRISP_ACTIONS.SESSION_DATA,
            [
              [
                [
                  this.translateService.instant('crisp.service.labels.tab'),
                  this.translateService.instant('crisp.service.tabs.' + routeData.activeMenu)
                ]
              ]
            ]
          ]);
        })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  setUserInfos(account: Account) {
    window['$crisp'].push([CRISP_METHODS.set, CRISP_ACTIONS.USER_EMAIL, [account.email]]);
    window['$crisp'].push([
      CRISP_METHODS.set,
      CRISP_ACTIONS.USER_NICKNAME,
      [account.lastName + ' ' + account.firstName]
    ]);
    window['$crisp'].push([CRISP_METHODS.set, CRISP_ACTIONS.USER_COMPANY, [account.organisationName]]);
    window['$crisp'].push([CRISP_METHODS.set, CRISP_ACTIONS.USER_PHONE, [account.organisation?.phone]]);
  }
}
