import { Routes } from '@angular/router';
import { MemberInviteComponent } from './invite/member-invite.component';
import { ConfirmationInvitationComponent } from './components/confirmation/confirmation-invitation.component';
import { SignatoryRedirectionComponent } from './components/loading/signatory-redirection.component';

export const MEMBER_ROUTES: Routes = [
  {
    path: 'construction-sites/:siteId/lot/:lotId/invite-member',
    component: MemberInviteComponent
  },
  {
    path: 'construction-sites/:siteId/client/:clientId/invite-member',
    component: MemberInviteComponent
  },
  {
    path: 'construction-sites/:siteId/lot/:lotId/invite-member/confirmation',
    component: ConfirmationInvitationComponent
  },
  {
    path: 'construction-sites/:siteId/lots/:lotId/member/:memberId/signatory',
    component: SignatoryRedirectionComponent
  },
  {
    path: 'sign/:id',
    component: SignatoryRedirectionComponent
  }
];
