/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RequestPartFundingAppealsFilters { 
    ids?: Array<string>;
    searchTerm?: string;
    states?: Array<RequestPartFundingAppealsFilters.StatesEnum>;
    mode?: RequestPartFundingAppealsFilters.ModeEnum;
    sitesFilter?: Array<string>;
    rmTypes?: Array<RequestPartFundingAppealsFilters.RmTypesEnum>;
    types?: Array<RequestPartFundingAppealsFilters.TypesEnum>;
    proof?: RequestPartFundingAppealsFilters.ProofEnum;
    minAmount?: number;
    maxAmount?: number;
    fromDate?: string;
    toDate?: string;
    fundingAppeals?: Array<number>;
}
export namespace RequestPartFundingAppealsFilters {
    export type StatesEnum = 'PENDING' | 'PAID' | 'LATE' | 'EXPIRED';
    export const StatesEnum = {
        Pending: 'PENDING' as StatesEnum,
        Paid: 'PAID' as StatesEnum,
        Late: 'LATE' as StatesEnum,
        Expired: 'EXPIRED' as StatesEnum
    };
    export type ModeEnum = 'REAL' | 'FIXED' | 'REAL_FIXED' | 'LOT_FIXED';
    export const ModeEnum = {
        Real: 'REAL' as ModeEnum,
        Fixed: 'FIXED' as ModeEnum,
        RealFixed: 'REAL_FIXED' as ModeEnum,
        LotFixed: 'LOT_FIXED' as ModeEnum
    };
    export type RmTypesEnum = 'REMIND' | 'REMIND_2' | 'INJUNCTION' | 'SIGNATORY' | 'OTHER';
    export const RmTypesEnum = {
        Remind: 'REMIND' as RmTypesEnum,
        Remind2: 'REMIND_2' as RmTypesEnum,
        Injunction: 'INJUNCTION' as RmTypesEnum,
        Signatory: 'SIGNATORY' as RmTypesEnum,
        Other: 'OTHER' as RmTypesEnum
    };
    export type TypesEnum = 'EMAIL' | 'MAIL';
    export const TypesEnum = {
        Email: 'EMAIL' as TypesEnum,
        Mail: 'MAIL' as TypesEnum
    };
    export type ProofEnum = 'ALL' | 'BILL' | 'ASSET';
    export const ProofEnum = {
        All: 'ALL' as ProofEnum,
        Bill: 'BILL' as ProofEnum,
        Asset: 'ASSET' as ProofEnum
    };
}


