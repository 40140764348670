/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MemberId } from './memberId';
import { Organisation } from './organisation';
import { Signature } from './signature';
import { SignatoryRemindHistory } from './signatoryRemindHistory';


export interface Lot { 
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    id?: string;
    name: string;
    description: string;
    serviceProviderName?: string;
    isCommitee?: boolean;
    isSigned?: boolean;
    isPercentFilled?: boolean;
    nbAgreementSignatures?: number;
    included?: boolean;
    billingAddress?: string;
    reference?: string;
    organisationLabel?: string;
    purchaseOrder?: string;
    fixedPercent?: number;
    serviceProvider?: Organisation;
    originalAmount?: number;
    totalAmount?: number;
    distribution?: number;
    nbAmendments?: number;
    nbReminders?: number;
    remindDate?: string;
    nbAutoReminders?: number;
    autoRemindDate?: string;
    members?: Array<MemberId>;
    role: Lot.RoleEnum;
    signature?: Signature;
    signatureUrl?: string;
    interCompanyDistribution?: number;
    signatoryRemindHistory?: Array<SignatoryRemindHistory>;
}
export namespace Lot {
    export type RoleEnum = 'MANAGER' | 'MEMBER' | 'PROJECT_OWNER' | 'GENERAL_CONTRACTOR';
    export const RoleEnum = {
        Manager: 'MANAGER' as RoleEnum,
        Member: 'MEMBER' as RoleEnum,
        ProjectOwner: 'PROJECT_OWNER' as RoleEnum,
        GeneralContractor: 'GENERAL_CONTRACTOR' as RoleEnum
    };
}


