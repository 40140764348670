import { Component, OnInit } from '@angular/core';
import { SignatoryCommandResourceService } from '../../../shared/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fc-cancel-form',
  templateUrl: './cancel-form.component.html'
})
export class CancelFormComponent implements OnInit {
  comment: string;
  constructionSiteId: string;
  memberId: string;
  wip = false;

  constructor(
    private signatoryCommandResourceService: SignatoryCommandResourceService,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.constructionSiteId = params.siteId;
      this.memberId = params.memberId;
    });
  }

  public send() {
    this.toasterService.info(this.translateService.instant('public.components.cancel.messages.pending'));
    this.wip = true;
    this.signatoryCommandResourceService
      .cancelSignatory(this.constructionSiteId, this.memberId, { comment: this.comment })
      .subscribe(
        () => {
          this.toasterService.success(this.translateService.instant('public.components.cancel.messages.success'));
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 4000);
        },
        () => {
          this.wip = false;
          this.toasterService.error(this.translateService.instant('public.components.cancel.messages.error'));
        }
      );
  }
}
