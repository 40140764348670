/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FundingAppealPayRemindRequest { 
    paymentDate?: string;
    paymentMode?: FundingAppealPayRemindRequest.PaymentModeEnum;
    items?: Array<string>;
}
export namespace FundingAppealPayRemindRequest {
    export type PaymentModeEnum = 'CHECK' | 'TRANSFER';
    export const PaymentModeEnum = {
        Check: 'CHECK' as PaymentModeEnum,
        Transfer: 'TRANSFER' as PaymentModeEnum
    };
}


