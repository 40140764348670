/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Member } from './member';


export interface Signatory { 
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    id?: string;
    member?: Member;
    state?: Signatory.StateEnum;
    comment?: string;
    lastRequestedDate?: string;
    nbReminders?: number;
}
export namespace Signatory {
    export type StateEnum = 'PENDING' | 'EXPIRED' | 'SIGNED' | 'DECLINED';
    export const StateEnum = {
        Pending: 'PENDING' as StateEnum,
        Expired: 'EXPIRED' as StateEnum,
        Signed: 'SIGNED' as StateEnum,
        Declined: 'DECLINED' as StateEnum
    };
}


