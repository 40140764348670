import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { FRONT_VERSION } from './app.constants';
import { DisplayMode, ValdemortConfig } from 'ngx-valdemort';
import { AccountService } from './features/account/account.service';
import LogRocket from 'logrocket';
import { Subscription } from 'rxjs';
import { CrispService } from './core/crisp/crisp.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HelpComponent } from './features/account/components/help/help.component';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'fc-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  locale: string;
  buildDate: string;
  private subscriptions: Subscription[] = [];

  @ViewChild('content', { static: true }) modal: NgbModal;
  private modalRef: NgbModalRef;
  isOpened = false;
  showNavbar = true;

  constructor(
    private translateService: TranslateService,
    public accountService: AccountService,
    private modalService: NgbModal,
    private crispService: CrispService,
    errorMessagesConfig: ValdemortConfig,
    private router: Router
  ) {
    window['$crisp'] = [];
    this.locale = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
    this.buildDate = FRONT_VERSION.buildDate;

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.showNavbar =
        event.url === '/' || event.url === '/construction-sites' || event.url.includes('/construction-sites?');
    });

    // Configure translation for app
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEn, 'en');

    translateService.setDefaultLang(environment.defaultLanguage);

    errorMessagesConfig.errorsClasses = 'invalid-feedback';
    errorMessagesConfig.displayMode = DisplayMode.ALL;
    errorMessagesConfig.shouldDisplayErrors = (control, form) => control.dirty || control.touched;

    if (!environment.production) {
      (window as any).faircost = { app: FRONT_VERSION };
    }

    this.subscriptions.push(
      accountService.events.subscribe((account) => {
        /* istanbul ignore next */
        if (!account) {
          return;
        }
        LogRocket.identify(account.id, {
          name: `${account.firstName} ${account.lastName}`,
          email: account.email
        });
        crispService.setUserInfos(account);
      })
    );

    crispService.initTabSubscription();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.crispService.unsubscribe();
  }

  /* istanbul ignore next */
  openModal() {
    this.isOpened = true;
    this.modalRef = this.modalService.open(HelpComponent, {
      size: 'lg',
      backdrop: true,
      windowClass: 'help-modal-class'
    });
    this.modalRef.result.then(() => {
      this.isOpened = false;
    });
  }
}
