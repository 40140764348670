import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, forwardRef } from '@angular/core';

@Directive({
  selector: '[fcNoFuture]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NoFutureValidator),
      multi: true
    }
  ]
})
export class NoFutureValidator implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return Date.parse(control.value) > Date.now() ? { noFuture: true } : null;
  }
}
