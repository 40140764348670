import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ValdemortModule } from 'ngx-valdemort';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

@NgModule({
  exports: [
    JwBootstrapSwitchNg2Module,
    HttpClientModule,
    FormsModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    ValdemortModule
  ]
})
/**
 * This module is used to import dans configure vendors libraries
 */
export class SharedLibsModule {}
