/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FundingAppealParamsDates } from './fundingAppealParamsDates';
import { BankInformation } from './bankInformation';
import { FixedParams } from './fixedParams';


export interface FundingAppealParams { 
    notBilled?: Array<string>;
    dates?: FundingAppealParamsDates;
    fixed?: FixedParams;
    percent?: number;
    deadline?: FundingAppealParams.DeadlineEnum;
    paymentMode?: FundingAppealParams.PaymentModeEnum;
    bankInformation?: BankInformation;
    paymentTerms?: string;
    isBilling?: boolean;
    displayRegistration?: boolean;
    joinExpenses?: boolean;
    displayEmail?: boolean;
    displayPhone?: boolean;
}
export namespace FundingAppealParams {
    export type DeadlineEnum = '30_DAYS' | 'TODAY' | 'END_OF_MONTH' | '45_DAYS' | 'END_OF_NEXT_MONTH';
    export const DeadlineEnum = {
        _30Days: '30_DAYS' as DeadlineEnum,
        Today: 'TODAY' as DeadlineEnum,
        EndOfMonth: 'END_OF_MONTH' as DeadlineEnum,
        _45Days: '45_DAYS' as DeadlineEnum,
        EndOfNextMonth: 'END_OF_NEXT_MONTH' as DeadlineEnum
    };
    export type PaymentModeEnum = 'CHECK' | 'TRANSFER';
    export const PaymentModeEnum = {
        Check: 'CHECK' as PaymentModeEnum,
        Transfer: 'TRANSFER' as PaymentModeEnum
    };
}


