import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  template: '',
  selector: 'fc-crisp'
})
export class CrispComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window['$crisp'] = [];
    window['CRISP_WEBSITE_ID'] = environment.crispWebsiteID;

    (() => {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);
      window['$crisp'].push(['do', 'chat:hide']);
      window['$crisp'].push(['on', 'chat:closed', this.hideChat]);
    })();
  }

  hideChat() {
    window['$crisp'].push(['do', 'chat:hide']);
  }
}
