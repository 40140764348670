import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructionSite, ConstructionSiteCommandResourceService, Lot, LotCommandResourceService } from '../../api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Editor, toHTML } from 'ngx-editor';

@Component({
  selector: 'fc-billing-edit',
  templateUrl: './billing-edit.component.html',
  styleUrls: ['./billing-edit.component.scss']
})
export class BillingEditComponent implements OnInit {
  lot: Lot;
  site: ConstructionSite;
  agreementMode: string;
  num = '';
  reference = '';
  address;
  editor: Editor;

  constructor(
    public activeModal: NgbActiveModal,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private lotCommandService: LotCommandResourceService,
    private constructionSiteCommandResourceService: ConstructionSiteCommandResourceService
  ) {}

  ngOnInit(): void {
    this.editor = new Editor();
    this.editor.setContent(this.address);
    this.num =
      this.agreementMode !== 'FIXED' && this.agreementMode !== 'REAL_FIXED'
        ? this.lot.purchaseOrder
        : this.site.purchaseOrder;
    this.address =
      this.agreementMode !== 'FIXED' && this.agreementMode !== 'REAL_FIXED'
        ? this.lot.billingAddress
        : this.site.billingAddress;
    this.reference = this.agreementMode !== 'FIXED' && this.agreementMode !== 'REAL_FIXED' ? this.lot.reference : '';
  }

  save() {
    let response;

    /* istanbul ignore next */
    const address =
      this.address && this.address['type'] && this.address['type'] === 'doc' ? toHTML(this.address) : this.address;

    if (this.agreementMode !== 'FIXED' && this.agreementMode !== 'REAL_FIXED') {
      this.lot.billingAddress = address;
      this.lot.purchaseOrder = this.num;
      this.lot.reference = this.reference;
      response = this.lotCommandService.updateLot(this.site.id, this.lot.id, this.lot);
    } else {
      this.site.billingAddress = address;
      this.site.purchaseOrder = this.num;
      response = this.constructionSiteCommandResourceService.updateSiteWithPic1(this.site.id, this.site);
    }

    /* istanbul ignore next */
    response.subscribe(
      () => {
        this.activeModal.close(true);
        this.toasterService.success(this.translateService.instant('lot-information.form.responses.success'));
      },
      () => {
        this.toasterService.error(this.translateService.instant('lot-information.form.responses.error'));
      }
    );
  }
}
