import { ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/browser';
import { AccountService } from '../../features/account/account.service';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any) {
    captureException(error.originalError || error);
    console.error(error);
  }
}
