import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { SharedLibsModule } from './shared-libs.module';
import { AccountService } from '../features/account/account.service';
import { ComponentsModule } from './components/components.module';
import { PipeModule } from './pipes/pipe.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxEditorModule } from 'ngx-editor';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [SharedLibsModule, ProgressbarModule.forRoot(), NgxEditorModule, NgSelectModule],
  exports: [SharedLibsModule, ComponentsModule, PipeModule, ProgressbarModule, NgxEditorModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'FR-fr'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    }
  ]
})
/**
 * This module is used to import and configure common modules for all project
 */
export class SharedModule {
  /**
   * Declare only services that needs to be singleton
   * Only one instance of these services will be shared among lazy loaded modules
   */
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AccountService // Must be a singleton
      ]
    };
  }
}
