/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UiConfiguration { 
    deepLinking?: boolean;
    displayOperationId?: boolean;
    defaultModelsExpandDepth?: number;
    defaultModelExpandDepth?: number;
    defaultModelRendering?: UiConfiguration.DefaultModelRenderingEnum;
    displayRequestDuration?: boolean;
    docExpansion?: UiConfiguration.DocExpansionEnum;
    filter?: object;
    maxDisplayedTags?: number;
    operationsSorter?: UiConfiguration.OperationsSorterEnum;
    showExtensions?: boolean;
    tagsSorter?: UiConfiguration.TagsSorterEnum;
    validatorUrl?: string;
    apisSorter?: string;
    jsonEditor?: boolean;
    showRequestHeaders?: boolean;
    supportedSubmitMethods?: Array<string>;
}
export namespace UiConfiguration {
    export type DefaultModelRenderingEnum = 'example' | 'model';
    export const DefaultModelRenderingEnum = {
        Example: 'example' as DefaultModelRenderingEnum,
        Model: 'model' as DefaultModelRenderingEnum
    };
    export type DocExpansionEnum = 'none' | 'list' | 'full';
    export const DocExpansionEnum = {
        None: 'none' as DocExpansionEnum,
        List: 'list' as DocExpansionEnum,
        Full: 'full' as DocExpansionEnum
    };
    export type OperationsSorterEnum = 'alpha' | 'method';
    export const OperationsSorterEnum = {
        Alpha: 'alpha' as OperationsSorterEnum,
        Method: 'method' as OperationsSorterEnum
    };
    export type TagsSorterEnum = 'alpha';
    export const TagsSorterEnum = {
        Alpha: 'alpha' as TagsSorterEnum
    };
}


