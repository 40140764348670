export * from './accountOrganisationCommandResource.service';
import { AccountOrganisationCommandResourceService } from './accountOrganisationCommandResource.service';
export * from './accountOrganisationQueryResource.service';
import { AccountOrganisationQueryResourceService } from './accountOrganisationQueryResource.service';
export * from './accountPreferencesCommandResource.service';
import { AccountPreferencesCommandResourceService } from './accountPreferencesCommandResource.service';
export * from './accountPreferencesQueryResource.service';
import { AccountPreferencesQueryResourceService } from './accountPreferencesQueryResource.service';
export * from './accountResource.service';
import { AccountResourceService } from './accountResource.service';
export * from './agreementCommandResource.service';
import { AgreementCommandResourceService } from './agreementCommandResource.service';
export * from './agreementQueryResource.service';
import { AgreementQueryResourceService } from './agreementQueryResource.service';
export * from './amendmentCommandResource.service';
import { AmendmentCommandResourceService } from './amendmentCommandResource.service';
export * from './amendmentQueryResource.service';
import { AmendmentQueryResourceService } from './amendmentQueryResource.service';
export * from './apiResourceController.service';
import { ApiResourceControllerService } from './apiResourceController.service';
export * from './auditResource.service';
import { AuditResourceService } from './auditResource.service';
export * from './authInfoResource.service';
import { AuthInfoResourceService } from './authInfoResource.service';
export * from './bankInformationCommandResource.service';
import { BankInformationCommandResourceService } from './bankInformationCommandResource.service';
export * from './constructionSiteAdminCommandResource.service';
import { ConstructionSiteAdminCommandResourceService } from './constructionSiteAdminCommandResource.service';
export * from './constructionSiteAdminQueryResource.service';
import { ConstructionSiteAdminQueryResourceService } from './constructionSiteAdminQueryResource.service';
export * from './constructionSiteCommandResource.service';
import { ConstructionSiteCommandResourceService } from './constructionSiteCommandResource.service';
export * from './constructionSiteQueryResource.service';
import { ConstructionSiteQueryResourceService } from './constructionSiteQueryResource.service';
export * from './constructionSiteStatsQueryResource.service';
import { ConstructionSiteStatsQueryResourceService } from './constructionSiteStatsQueryResource.service';
export * from './expenseCategoryQueryResource.service';
import { ExpenseCategoryQueryResourceService } from './expenseCategoryQueryResource.service';
export * from './expenseCommandResource.service';
import { ExpenseCommandResourceService } from './expenseCommandResource.service';
export * from './expenseQueryResource.service';
import { ExpenseQueryResourceService } from './expenseQueryResource.service';
export * from './expensesCategoryStatsQueryResource.service';
import { ExpensesCategoryStatsQueryResourceService } from './expensesCategoryStatsQueryResource.service';
export * from './expensesStatsQueryResource.service';
import { ExpensesStatsQueryResourceService } from './expensesStatsQueryResource.service';
export * from './fundingAppealAdvancementCommandResource.service';
import { FundingAppealAdvancementCommandResourceService } from './fundingAppealAdvancementCommandResource.service';
export * from './fundingAppealAdvancementQueryResource.service';
import { FundingAppealAdvancementQueryResourceService } from './fundingAppealAdvancementQueryResource.service';
export * from './fundingAppealCertificateQueryResource.service';
import { FundingAppealCertificateQueryResourceService } from './fundingAppealCertificateQueryResource.service';
export * from './fundingAppealCommandResource.service';
import { FundingAppealCommandResourceService } from './fundingAppealCommandResource.service';
export * from './fundingAppealExportQueryResource.service';
import { FundingAppealExportQueryResourceService } from './fundingAppealExportQueryResource.service';
export * from './fundingAppealQueryResource.service';
import { FundingAppealQueryResourceService } from './fundingAppealQueryResource.service';
export * from './fundingAppealsStatsQueryResource.service';
import { FundingAppealsStatsQueryResourceService } from './fundingAppealsStatsQueryResource.service';
export * from './invitationCommandResource.service';
import { InvitationCommandResourceService } from './invitationCommandResource.service';
export * from './loginResource.service';
import { LoginResourceService } from './loginResource.service';
export * from './logoutResource.service';
import { LogoutResourceService } from './logoutResource.service';
export * from './lotCommandResource.service';
import { LotCommandResourceService } from './lotCommandResource.service';
export * from './lotMemberCommandResource.service';
import { LotMemberCommandResourceService } from './lotMemberCommandResource.service';
export * from './lotMemberQueryResource.service';
import { LotMemberQueryResourceService } from './lotMemberQueryResource.service';
export * from './lotQueryResource.service';
import { LotQueryResourceService } from './lotQueryResource.service';
export * from './memberCommandResource.service';
import { MemberCommandResourceService } from './memberCommandResource.service';
export * from './memberQueryResource.service';
import { MemberQueryResourceService } from './memberQueryResource.service';
export * from './notificationCommandResource.service';
import { NotificationCommandResourceService } from './notificationCommandResource.service';
export * from './notificationQueryResource.service';
import { NotificationQueryResourceService } from './notificationQueryResource.service';
export * from './organisationQueryResource.service';
import { OrganisationQueryResourceService } from './organisationQueryResource.service';
export * from './organisationRegistrationCommandResource.service';
import { OrganisationRegistrationCommandResourceService } from './organisationRegistrationCommandResource.service';
export * from './organisationRegistrationQueryResource.service';
import { OrganisationRegistrationQueryResourceService } from './organisationRegistrationQueryResource.service';
export * from './organisationSearchResource.service';
import { OrganisationSearchResourceService } from './organisationSearchResource.service';
export * from './paymentCommandResource.service';
import { PaymentCommandResourceService } from './paymentCommandResource.service';
export * from './paymentIdentityCommandResource.service';
import { PaymentIdentityCommandResourceService } from './paymentIdentityCommandResource.service';
export * from './paymentIdentityQueryResource.service';
import { PaymentIdentityQueryResourceService } from './paymentIdentityQueryResource.service';
export * from './paymentQueryResource.service';
import { PaymentQueryResourceService } from './paymentQueryResource.service';
export * from './registeredMailCommandResource.service';
import { RegisteredMailCommandResourceService } from './registeredMailCommandResource.service';
export * from './registeredMailQueryResource.service';
import { RegisteredMailQueryResourceService } from './registeredMailQueryResource.service';
export * from './reportCommandResource.service';
import { ReportCommandResourceService } from './reportCommandResource.service';
export * from './reportQueryResource.service';
import { ReportQueryResourceService } from './reportQueryResource.service';
export * from './signatoryCommandResource.service';
import { SignatoryCommandResourceService } from './signatoryCommandResource.service';
export * from './signatoryQueryResource.service';
import { SignatoryQueryResourceService } from './signatoryQueryResource.service';
export * from './userResource.service';
import { UserResourceService } from './userResource.service';
export const APIS = [AccountOrganisationCommandResourceService, AccountOrganisationQueryResourceService, AccountPreferencesCommandResourceService, AccountPreferencesQueryResourceService, AccountResourceService, AgreementCommandResourceService, AgreementQueryResourceService, AmendmentCommandResourceService, AmendmentQueryResourceService, ApiResourceControllerService, AuditResourceService, AuthInfoResourceService, BankInformationCommandResourceService, ConstructionSiteAdminCommandResourceService, ConstructionSiteAdminQueryResourceService, ConstructionSiteCommandResourceService, ConstructionSiteQueryResourceService, ConstructionSiteStatsQueryResourceService, ExpenseCategoryQueryResourceService, ExpenseCommandResourceService, ExpenseQueryResourceService, ExpensesCategoryStatsQueryResourceService, ExpensesStatsQueryResourceService, FundingAppealAdvancementCommandResourceService, FundingAppealAdvancementQueryResourceService, FundingAppealCertificateQueryResourceService, FundingAppealCommandResourceService, FundingAppealExportQueryResourceService, FundingAppealQueryResourceService, FundingAppealsStatsQueryResourceService, InvitationCommandResourceService, LoginResourceService, LogoutResourceService, LotCommandResourceService, LotMemberCommandResourceService, LotMemberQueryResourceService, LotQueryResourceService, MemberCommandResourceService, MemberQueryResourceService, NotificationCommandResourceService, NotificationQueryResourceService, OrganisationQueryResourceService, OrganisationRegistrationCommandResourceService, OrganisationRegistrationQueryResourceService, OrganisationSearchResourceService, PaymentCommandResourceService, PaymentIdentityCommandResourceService, PaymentIdentityQueryResourceService, PaymentQueryResourceService, RegisteredMailCommandResourceService, RegisteredMailQueryResourceService, ReportCommandResourceService, ReportQueryResourceService, SignatoryCommandResourceService, SignatoryQueryResourceService, UserResourceService];
