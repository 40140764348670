import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'construction-sites',
    loadChildren: () =>
      import('./features/construction-site/construction-site.module').then((m) => m.ConstructionSiteModule)
  },
  {
    path: 'public',
    loadChildren: () => import('./features/public/public.module').then((m) => m.PublicModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./features/account/account.module').then((m) => m.AccountModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./features/error/error.module').then((m) => m.ErrorModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'construction-sites'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
