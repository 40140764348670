import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ConstructionSiteFormComponent } from '../../../detail/form/construction-site-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APP_EVENTS } from '../../../../../shared/event/event.enum';
import { JhiEventManager } from 'ng-jhipster';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstructionSite } from '../../../../../shared/api';
import { EmailsUtils } from '../../../../../shared/utils/emails.utils';
import { AccountService } from '../../../../account/account.service';
import { ReportListComponent } from '../../../report-list/report-list.component';

@Component({
  selector: 'fc-construction-site-menu',
  templateUrl: './construction-site-menu.component.html',
  styleUrls: ['./construction-site-menu.component.scss']
})
export class ConstructionSiteMenuComponent {
  @Input() constructionSite: ConstructionSite = { name: null, address: null, client: null };

  constructor(
    private modalService: NgbModal,
    private event: JhiEventManager,
    private router: Router,
    public accountService: AccountService
  ) {}

  edit(constructionSite: ConstructionSite) {
    const modalRef = this.modalService.open(ConstructionSiteFormComponent, { windowClass: 'fullscreen' });
    modalRef.componentInstance.constructionSite = { ...constructionSite };
    modalRef.result.then(
      (result) => {
        // if result is null, construction site have been deleted
        this.constructionSite = result;
        if (result) {
          this.event.broadcast({ name: APP_EVENTS.CONSTRUCTION_SITE_UPDATED, content: result });
        } else {
          this.router.navigate(['construction-sites']);
        }
      },
      () => {}
    );
  }

  sendEmail(to: string, $event: MouseEvent) {
    const emails = EmailsUtils.emailsToString(to, this.constructionSite.members, this.accountService.account);
    window.location.href = 'mailto:' + emails.lots + '?&cc=' + emails.all;
    return emails;
  }

  openReportModal() {
    const modalRef = this.modalService.open(ReportListComponent, { windowClass: 'fullscreen' });
    modalRef.componentInstance.constructionSite = { ...this.constructionSite };
    /* istanbul ignore next */
    modalRef.result.then(() => {});
  }
}
