import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fc-file-banner',
  templateUrl: './file-banner.component.html',
  styleUrls: ['./file-banner.component.scss']
})
export class FileBannerComponent implements OnInit {
  @Input() title: string;
  @Input() nbPages: number;
  @Input() currentPage: number;
  @Output() updatePage: EventEmitter<number> = new EventEmitter<number>();
  @Output() downloadFile: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
