/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RegisteredMailFilter { 
    constructionSiteIds?: Array<string>;
    registeredMailTypes?: Array<RegisteredMailFilter.RegisteredMailTypesEnum>;
    organisationNames?: Array<string>;
    fromDate?: string;
    toDate?: string;
}
export namespace RegisteredMailFilter {
    export type RegisteredMailTypesEnum = 'REMIND' | 'REMIND_2' | 'INJUNCTION' | 'SIGNATORY' | 'OTHER';
    export const RegisteredMailTypesEnum = {
        Remind: 'REMIND' as RegisteredMailTypesEnum,
        Remind2: 'REMIND_2' as RegisteredMailTypesEnum,
        Injunction: 'INJUNCTION' as RegisteredMailTypesEnum,
        Signatory: 'SIGNATORY' as RegisteredMailTypesEnum,
        Other: 'OTHER' as RegisteredMailTypesEnum
    };
}


