import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { groupBy } from 'lodash';
import { MemberInvitation } from '../shared/models/member-invitation';
import { AuthorityEnum } from '../../account/shared/models/authority.enum';
import { AccountService } from '../../account/account.service';
import { ConfirmationModalService } from '../../../shared/components/confirmation/services/confirmation-modal.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ManagerOfPipe } from '../../../shared/pipes/manager-of.pipe';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { Account } from '../../account/shared/models/account';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ConstructionSite,
  Invitations,
  Lot,
  LotMemberCommandResourceService,
  Member,
  MemberCommandResourceService
} from '../../../shared/api';
import { EmailCheckerService } from '../../../core/emails/email-checker/email-checker.service';

@Component({
  selector: 'fc-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss'],
  providers: [ManagerOfPipe]
})
export class MemberListComponent implements OnChanges, OnInit {
  @Input() constructionSite: ConstructionSite;
  @Input() lot: Lot;
  @Input() lots: Lot[];
  @Input() members: Member[]; // List of existing members to display
  @Input() suggestedMembers: Member[]; // List of suggested members to add (non invitation mode)
  @Input() config: {
    invitationMode?: boolean;
    placeholderKey?: string;
    showSelectRole?: boolean;
    showSelectLot?: boolean;
    showMemberList?: boolean;
  };
  @Input() displayRoles: AuthorityEnum[];
  @Output() sendInvitation: EventEmitter<{ invitations: Invitations; lotId?: string }>;
  @Output() changeMember: EventEmitter<Member>;
  membersByAuthorities: { [any: string]: Member[] }; // Members grouped by authorities (manager, member, etc)
  invitation: MemberInvitation; // Invitation object (invitation mode)
  emails: {
    email: string;
    isFormatValid: boolean;
    isMxValid: boolean;
    isDisposable: boolean;
    isScoreValid: boolean;
    hasToSendInvitation: boolean;
  }[] = [];
  roles: AuthorityEnum[] = [];
  invitationLots: Lot[];
  userRole: string;
  account: Account;

  constructor(
    private accountService: AccountService,
    private confirmationModalService: ConfirmationModalService,
    private memberCommandResourceService: MemberCommandResourceService,
    private lotMemberCommandResourceService: LotMemberCommandResourceService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private confirmationModal: ConfirmationModalService,
    public activeModal: NgbActiveModal,
    public emailChecker: EmailCheckerService
  ) {
    this.config = {};
    this.invitation = {};
    this.sendInvitation = new EventEmitter();
    this.changeMember = new EventEmitter();

    this.displayRoles = [
      AuthorityEnum.ROLE_MANAGER,
      AuthorityEnum.ROLE_MEMBER,
      AuthorityEnum.ROLE_GENERAL_CONTRACTOR,
      AuthorityEnum.ROLE_PROJECT_OWNER
    ];
  }

  ngOnInit() {
    this.userRole = this.accountService.getRoleIn(this.constructionSite as any);
    this.account = this.accountService.account;

    this.configureByRole();

    if (isNullOrUndefined(this.config.showSelectRole)) {
      this.config.showSelectRole = true;
    }

    if (isNullOrUndefined(this.config.showMemberList)) {
      this.config.showMemberList = true;
    }

    if (isNullOrUndefined(this.config.invitationMode)) {
      this.config.invitationMode = false;
    }

    if (this.lot) {
      this.invitation.lotId = this.lot.id;
    }

    if (isNullOrUndefined(this.config.showSelectLot)) {
      this.config.showSelectLot = true;
    }

    this.configureByRole();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.members && changes.members.currentValue !== changes.members.previousValue) {
      // Group members by role
      this.membersByAuthorities = groupBy(changes.members.currentValue, 'role');
    }
    if (this.members && this.suggestedMembers) {
      // Show only members that are not already members of the lot
      this.suggestedMembers = this.suggestedMembers.filter(
        (suggestedMember) => !this.members.some((member) => member.id === suggestedMember.id)
      );
    }
  }

  /**
   * Triggered when the user specify a list of members a role
   * and hit the send button
   */
  sendInvitations() {
    this.invitation.emails =
      this.invitation.emails && this.invitation.emails.length
        ? this.invitation.emails.map((email) => {
            return {
              email: email.email.trim(),
              hasToSendInvitation: email.hasToSendInvitation
            };
          })
        : this.emails.map((email) => {
            return {
              email: email.email.trim(),
              hasToSendInvitation: email.hasToSendInvitation
            };
          });

    const invitations: Invitations = { invitations: null };

    invitations.invitations = this.invitation.emails.map((email) => {
      return {
        email: email.email.toLowerCase(),
        hasToSendInvitation: email.hasToSendInvitation,
        role: this.invitation.role ? AuthorityEnum[`ROLE_${this.invitation.role}`] : undefined
      };
    });

    this.sendInvitation.emit({ invitations, lotId: this.invitation.lotId });
    this.invitation.emails = [];
    this.emails = [];
  }

  updateMember(member: Member) {
    if (this.isOwnerOrContractor(member)) {
      this.updateMemberWithoutLot(member);
    } else {
      member.lotId = null;
    }
  }

  updateLot(member: Member) {
    if (!this.isOwnerOrContractor(member)) {
      this.updateMemberWithLot(member);
    }
  }

  /**
   * Returns true  for a given member if:
   * - The member is not the current user
   * - The member is not the construction-site's creator
   */
  isCurrentUser(member: Member): boolean {
    return (
      member.user &&
      (this.accountService.account.id === member.user.id || this.constructionSite.createdBy === member.user.login)
    );
  }

  deleteMember(member: Member) {
    this.confirmationModalService
      .confirm(
        'member.components.list.labels.delete.title',
        'member.components.list.labels.delete.guide',
        this.translateService.instant('app.delete'),
        'confirmation.form.buttons.delete',
        null
      )
      .subscribe((valid) => {
        let deleteObservable: Observable<any>;

        if (!valid) {
          return;
        }

        if (this.lot && this.lot.id) {
          deleteObservable = this.lotMemberCommandResourceService.deleteLotMember(
            this.constructionSite.id,
            this.lot.id,
            member.id
          );
        } else {
          deleteObservable = this.memberCommandResourceService.deleteMember(this.constructionSite.id, member.id);
        }

        deleteObservable.subscribe(
          () => {
            this.changeMember.emit(null);
            this.toasterService.success(
              this.translateService.instant('member.components.list.messages.member-delete-success')
            );
          },
          () => {
            this.toasterService.error(
              this.translateService.instant('member.components.list.messages.member-delete-error')
            );
          }
        );
      });
  }

  /**
   * When a user add a new member email
   * If not a valid email nothing happens (value is rejected)
   */
  addEmailTag(email: string): Member | boolean {
    if (Boolean(Validators.email({ value: email } as any))) {
      return false;
    }

    const member: Member = { role: null };
    member.email = email;
    return member;
  }

  searchMember(term: string, item: Member) {
    return item.email.includes(term);
  }

  lotIsRequired(role: string): boolean {
    return role === AuthorityEnum.ROLE_MANAGER || role === AuthorityEnum.ROLE_MEMBER;
  }

  lotIsDisabled(role: string): boolean {
    return role === AuthorityEnum.ROLE_PROJECT_OWNER || role === AuthorityEnum.ROLE_GENERAL_CONTRACTOR;
  }

  changeRole(role: string) {
    if (this.lotIsDisabled(role)) {
      this.invitation.lotId = null;
    }
  }

  private updateMemberWithoutLot(member: Member) {
    this.memberCommandResourceService.updateMember(this.constructionSite.id, member.id, member).subscribe(
      () => {
        this.changeMember.emit(member);
        this.toasterService.success(
          this.translateService.instant('member.components.list.messages.member-save-success')
        );
      },
      () => {
        this.changeMember.emit(null);
        this.toasterService.error(this.translateService.instant('member.components.list.messages.member-save-error'));
      }
    );
  }

  private updateMemberWithLot(member: Member) {
    const invitations: Invitations = { invitations: null };
    invitations.invitations = [
      {
        email: member.email,
        role: member.role,
        hasToSendInvitation: member.hasToSendInvitation
      }
    ];

    this.sendInvitation.emit({ invitations, lotId: member.lotId });
  }

  private configureByRole() {
    switch (this.userRole) {
      case AuthorityEnum.ROLE_MANAGER:
        this.roles = [
          AuthorityEnum.ROLE_MEMBER,
          AuthorityEnum.ROLE_MANAGER,
          AuthorityEnum.ROLE_GENERAL_CONTRACTOR,
          AuthorityEnum.ROLE_PROJECT_OWNER
        ];
        this.invitationLots = this.lots;
        break;
      case AuthorityEnum.ROLE_MEMBER:
        this.roles = [AuthorityEnum.ROLE_MEMBER];
        this.invitation.role = AuthorityEnum.ROLE_MEMBER;
        if (this.lots) {
          this.invitationLots = this.lots.filter((lot) =>
            lot.members.find((member) => member.login === this.accountService.account.login)
          );
          this.invitation.lotId = this.invitationLots[0].id;
        }
        break;
      default:
        this.roles = [
          AuthorityEnum.ROLE_MEMBER,
          AuthorityEnum.ROLE_GENERAL_CONTRACTOR,
          AuthorityEnum.ROLE_PROJECT_OWNER
        ];
        this.invitationLots = this.lots;
        break;
    }
  }

  private isOwnerOrContractor(member: Member): boolean {
    return member.role === AuthorityEnum.ROLE_GENERAL_CONTRACTOR || member.role === AuthorityEnum.ROLE_PROJECT_OWNER;
  }

  removeMember({ member }: { member: Member }) {
    this.confirmationModal
      .confirm(
        'member.components.list.labels.delete.title',
        'member.components.list.labels.delete.guide',
        this.translateService.instant('app.delete'),
        'confirmation.form.buttons.delete',
        null
      )
      .subscribe((result) => {
        if (result) {
          this.memberCommandResourceService.deleteMember(this.constructionSite.id, member.id).subscribe(
            () => {
              this.activeModal.close(null);
              this.changeMember.emit(member);
              this.toasterService.success(
                this.translateService.instant('member.components.list.messages.member-delete-success')
              );
            },
            () =>
              this.toasterService.error(
                this.translateService.instant('member.components.list.messages.member-delete-error')
              )
          );
        }
      });
  }
}
