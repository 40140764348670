/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConstructionSite } from './constructionSite';


export interface RequestPartFundingAppealsExportItems { 
    siteId?: string;
    agreementVersion?: number;
    agreementMode?: RequestPartFundingAppealsExportItems.AgreementModeEnum;
    site?: ConstructionSite;
    items?: Array<string>;
}
export namespace RequestPartFundingAppealsExportItems {
    export type AgreementModeEnum = 'REAL' | 'FIXED' | 'REAL_FIXED' | 'LOT_FIXED';
    export const AgreementModeEnum = {
        Real: 'REAL' as AgreementModeEnum,
        Fixed: 'FIXED' as AgreementModeEnum,
        RealFixed: 'REAL_FIXED' as AgreementModeEnum,
        LotFixed: 'LOT_FIXED' as AgreementModeEnum
    };
}


