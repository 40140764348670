import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountOrganisationCommandResourceService } from './api/accountOrganisationCommandResource.service';
import { AccountOrganisationQueryResourceService } from './api/accountOrganisationQueryResource.service';
import { AccountPreferencesCommandResourceService } from './api/accountPreferencesCommandResource.service';
import { AccountPreferencesQueryResourceService } from './api/accountPreferencesQueryResource.service';
import { AccountResourceService } from './api/accountResource.service';
import { AgreementCommandResourceService } from './api/agreementCommandResource.service';
import { AgreementQueryResourceService } from './api/agreementQueryResource.service';
import { AmendmentCommandResourceService } from './api/amendmentCommandResource.service';
import { AmendmentQueryResourceService } from './api/amendmentQueryResource.service';
import { ApiResourceControllerService } from './api/apiResourceController.service';
import { AuditResourceService } from './api/auditResource.service';
import { AuthInfoResourceService } from './api/authInfoResource.service';
import { BankInformationCommandResourceService } from './api/bankInformationCommandResource.service';
import { ConstructionSiteAdminCommandResourceService } from './api/constructionSiteAdminCommandResource.service';
import { ConstructionSiteAdminQueryResourceService } from './api/constructionSiteAdminQueryResource.service';
import { ConstructionSiteCommandResourceService } from './api/constructionSiteCommandResource.service';
import { ConstructionSiteQueryResourceService } from './api/constructionSiteQueryResource.service';
import { ConstructionSiteStatsQueryResourceService } from './api/constructionSiteStatsQueryResource.service';
import { ExpenseCategoryQueryResourceService } from './api/expenseCategoryQueryResource.service';
import { ExpenseCommandResourceService } from './api/expenseCommandResource.service';
import { ExpenseQueryResourceService } from './api/expenseQueryResource.service';
import { ExpensesCategoryStatsQueryResourceService } from './api/expensesCategoryStatsQueryResource.service';
import { ExpensesStatsQueryResourceService } from './api/expensesStatsQueryResource.service';
import { FundingAppealAdvancementCommandResourceService } from './api/fundingAppealAdvancementCommandResource.service';
import { FundingAppealAdvancementQueryResourceService } from './api/fundingAppealAdvancementQueryResource.service';
import { FundingAppealCertificateQueryResourceService } from './api/fundingAppealCertificateQueryResource.service';
import { FundingAppealClosingCommandResourceService } from './api/fundingAppealClosingCommandResource.service';
import { FundingAppealClosingQueryResourceService } from './api/fundingAppealClosingQueryResource.service';
import { FundingAppealCommandResourceService } from './api/fundingAppealCommandResource.service';
import { FundingAppealExportQueryResourceService } from './api/fundingAppealExportQueryResource.service';
import { FundingAppealFixedCommandResourceService } from './api/fundingAppealFixedCommandResource.service';
import { FundingAppealFixedQueryResourceService } from './api/fundingAppealFixedQueryResource.service';
import { FundingAppealLotFixedCommandResourceService } from './api/fundingAppealLotFixedCommandResource.service';
import { FundingAppealLotFixedQueryResourceService } from './api/fundingAppealLotFixedQueryResource.service';
import { FundingAppealQueryResourceService } from './api/fundingAppealQueryResource.service';
import { FundingAppealRealCommandResourceService } from './api/fundingAppealRealCommandResource.service';
import { FundingAppealRealQueryResourceService } from './api/fundingAppealRealQueryResource.service';
import { FundingAppealsStatsQueryResourceService } from './api/fundingAppealsStatsQueryResource.service';
import { InvitationCommandResourceService } from './api/invitationCommandResource.service';
import { LoginResourceService } from './api/loginResource.service';
import { LogoutResourceService } from './api/logoutResource.service';
import { LotCommandResourceService } from './api/lotCommandResource.service';
import { LotMemberCommandResourceService } from './api/lotMemberCommandResource.service';
import { LotMemberQueryResourceService } from './api/lotMemberQueryResource.service';
import { LotQueryResourceService } from './api/lotQueryResource.service';
import { MemberCommandResourceService } from './api/memberCommandResource.service';
import { MemberQueryResourceService } from './api/memberQueryResource.service';
import { NotificationCommandResourceService } from './api/notificationCommandResource.service';
import { NotificationQueryResourceService } from './api/notificationQueryResource.service';
import { OrganisationQueryResourceService } from './api/organisationQueryResource.service';
import { OrganisationRegistrationCommandResourceService } from './api/organisationRegistrationCommandResource.service';
import { OrganisationRegistrationQueryResourceService } from './api/organisationRegistrationQueryResource.service';
import { OrganisationSearchResourceService } from './api/organisationSearchResource.service';
import { PaymentCommandResourceService } from './api/paymentCommandResource.service';
import { PaymentIdentityCommandResourceService } from './api/paymentIdentityCommandResource.service';
import { PaymentIdentityQueryResourceService } from './api/paymentIdentityQueryResource.service';
import { PaymentQueryResourceService } from './api/paymentQueryResource.service';
import { RegisteredMailCommandResourceService } from './api/registeredMailCommandResource.service';
import { RegisteredMailQueryResourceService } from './api/registeredMailQueryResource.service';
import { ReportCommandResourceService } from './api/reportCommandResource.service';
import { ReportQueryResourceService } from './api/reportQueryResource.service';
import { SignatoryCommandResourceService } from './api/signatoryCommandResource.service';
import { SignatoryQueryResourceService } from './api/signatoryQueryResource.service';
import { UserResourceService } from './api/userResource.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
