import { Component, OnChanges } from '@angular/core';

@Component({
  selector: 'fc-confirmation-invitation',
  templateUrl: './confirmation-invitation.component.html',
  styleUrls: ['./confirmation-invitation.component.scss']
})
export class ConfirmationInvitationComponent {
  constructor() {}
}
