/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

  import { Agreement } from '../model/models';
  import { EstimatedBudgetRequestPart } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



    @Injectable({
    providedIn: 'root'
    })
    export class AgreementCommandResourceService {

  protected basePath = 'https://dev.thefaircost.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
  if (configuration) {
  this.configuration = configuration;
  }
  if (typeof this.configuration.basePath !== 'string') {
  if (typeof basePath !== 'string') {
  basePath = this.basePath;
  }
  this.configuration.basePath = basePath;
  }
  this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

    /**
    * @param consumes string[] mime-types
    * @return true: consumes contains 'multipart/form-data', false: otherwise
    */
    private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
    if (form === consume) {
    return true;
    }
    }
    return false;
    }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
  if (typeof value === "object" && value instanceof Date === false) {
  httpParams = this.addToHttpParamsRecursive(httpParams, value);
  } else {
  httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
  }
  return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
  if (value == null) {
  return httpParams;
  }

  if (typeof value === "object") {
    if (Array.isArray(value)) {
    (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
    } else if (value instanceof Date) {
    if (key != null) {
    httpParams = httpParams.append(key,
    (value as Date).toISOString().substr(0, 10));
    } else {
    throw Error("key may not be null if value is Date");
    }
            } else {
    Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
    httpParams, value[k], key != null ? `${key}.${k}` : k));
    }
  } else if (key != null) {
  httpParams = httpParams.append(key, value);
  } else {
  throw Error("key may not be null if value is not object or array");
  }
  return httpParams;
  }

    /**
        * @param constructionSiteId 
        * @param document 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public addAgreementAppendix(constructionSiteId: string, document?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public addAgreementAppendix(constructionSiteId: string, document?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public addAgreementAppendix(constructionSiteId: string, document?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public addAgreementAppendix(constructionSiteId: string, document?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling addAgreementAppendix.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

      const canConsumeForm = this.canConsumeForm(consumes);

      let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
      formParams = new FormData();
      } else {
      formParams = new HttpParams({encoder: this.encoder});
      }

          if (document !== undefined) {
          formParams = formParams.append('document', <any>document) as any || formParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/latest/appendix`,
    convertFormParamsToString ? formParams.toString() : formParams,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param removedFiles 
        * @param agreement 
        * @param documents 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public addWithDocuments(constructionSiteId: string, removedFiles?: Array<string>, agreement?: Agreement, documents?: Array<Blob>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Agreement>;
    public addWithDocuments(constructionSiteId: string, removedFiles?: Array<string>, agreement?: Agreement, documents?: Array<Blob>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Agreement>>;
    public addWithDocuments(constructionSiteId: string, removedFiles?: Array<string>, agreement?: Agreement, documents?: Array<Blob>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Agreement>>;
    public addWithDocuments(constructionSiteId: string, removedFiles?: Array<string>, agreement?: Agreement, documents?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling addWithDocuments.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (removedFiles) {
            removedFiles.forEach((element) => {
              queryParameters = this.addToHttpParams(queryParameters,
                <any>element, 'removed-files');
              })
          }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

      const canConsumeForm = this.canConsumeForm(consumes);

      let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
      formParams = new FormData();
      } else {
      formParams = new HttpParams({encoder: this.encoder});
      }

          if (agreement !== undefined) {
          formParams = formParams.append('agreement', useForm ? new Blob([JSON.stringify(agreement)], {type: 'application/json'}) : <any>agreement) as any || formParams;
        }
          if (documents) {
            if (useForm) {
          documents.forEach((element) => {
            formParams = formParams.append('documents', <any>element) as any || formParams;
            })
            } else {
            formParams = formParams.append('documents', documents.join(COLLECTION_FORMATS['csv'])) as any || formParams;
            }
          }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<Agreement>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements`,
    convertFormParamsToString ? formParams.toString() : formParams,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public exportBP(constructionSiteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<Array<string>>;
    public exportBP(constructionSiteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<HttpResponse<Array<string>>>;
    public exportBP(constructionSiteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<HttpEvent<Array<string>>>;
    public exportBP(constructionSiteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling exportBP.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<Array<string>>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/exportBP`,
    null,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param estimatedBudgetRequestPart 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public updateBP(constructionSiteId: string, estimatedBudgetRequestPart: EstimatedBudgetRequestPart, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateBP(constructionSiteId: string, estimatedBudgetRequestPart: EstimatedBudgetRequestPart, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateBP(constructionSiteId: string, estimatedBudgetRequestPart: EstimatedBudgetRequestPart, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateBP(constructionSiteId: string, estimatedBudgetRequestPart: EstimatedBudgetRequestPart, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling updateBP.');
        }
        if (estimatedBudgetRequestPart === null || estimatedBudgetRequestPart === undefined) {
        throw new Error('Required parameter estimatedBudgetRequestPart was null or undefined when calling updateBP.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
    headers = headers.set('Content-Type', httpContentTypeSelected);
    }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/estimated-budget`,
    estimatedBudgetRequestPart,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param removedFiles 
        * @param agreement 
        * @param documents 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public updateWithDoc1(constructionSiteId: string, removedFiles?: Array<string>, agreement?: Agreement, documents?: Array<Blob>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Agreement>;
    public updateWithDoc1(constructionSiteId: string, removedFiles?: Array<string>, agreement?: Agreement, documents?: Array<Blob>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Agreement>>;
    public updateWithDoc1(constructionSiteId: string, removedFiles?: Array<string>, agreement?: Agreement, documents?: Array<Blob>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Agreement>>;
    public updateWithDoc1(constructionSiteId: string, removedFiles?: Array<string>, agreement?: Agreement, documents?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling updateWithDoc1.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (removedFiles) {
            removedFiles.forEach((element) => {
              queryParameters = this.addToHttpParams(queryParameters,
                <any>element, 'removed-files');
              })
          }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
            'application/json'
        ];

      const canConsumeForm = this.canConsumeForm(consumes);

      let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
      formParams = new FormData();
      } else {
      formParams = new HttpParams({encoder: this.encoder});
      }

          if (agreement !== undefined) {
          formParams = formParams.append('agreement', useForm ? new Blob([JSON.stringify(agreement)], {type: 'application/json'}) : <any>agreement) as any || formParams;
        }
          if (documents) {
            if (useForm) {
          documents.forEach((element) => {
            formParams = formParams.append('documents', <any>element) as any || formParams;
            })
            } else {
            formParams = formParams.append('documents', documents.join(COLLECTION_FORMATS['csv'])) as any || formParams;
            }
          }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.put<Agreement>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/agreements/latest`,
    convertFormParamsToString ? formParams.toString() : formParams,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

}
