/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdvancementResponse { 
    billNumber?: string;
    billIndex?: number;
    firstLineAmount?: number;
    firstLineAmountInclTaxes?: number;
    amountInclTaxes?: number;
    amountExclTaxes?: number;
    paymentTerms?: string;
    advancementPercent?: number;
    advancementAmount?: number;
    billUrl?: string;
    pictureUrl?: string;
}

