/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Pageable } from './pageable';


export interface RegisteredMailPageableFilter { 
    pageable?: Pageable;
    fromDate?: string;
    toDate?: string;
    organisationNames?: Array<string>;
    registeredMailTypes?: Array<RegisteredMailPageableFilter.RegisteredMailTypesEnum>;
    constructionSiteIds?: Array<string>;
}
export namespace RegisteredMailPageableFilter {
    export type RegisteredMailTypesEnum = 'REMIND' | 'REMIND_2' | 'INJUNCTION' | 'SIGNATORY' | 'OTHER';
    export const RegisteredMailTypesEnum = {
        Remind: 'REMIND' as RegisteredMailTypesEnum,
        Remind2: 'REMIND_2' as RegisteredMailTypesEnum,
        Injunction: 'INJUNCTION' as RegisteredMailTypesEnum,
        Signatory: 'SIGNATORY' as RegisteredMailTypesEnum,
        Other: 'OTHER' as RegisteredMailTypesEnum
    };
}


