import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive, forwardRef, Input } from '@angular/core';

@Directive({
  selector: '[fcNoBefore]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NoBeforeValidator),
      multi: true
    }
  ]
})
export class NoBeforeValidator implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('fromDateProps') fromDateProps: string;
  // tslint:disable-next-line:no-input-rename
  @Input('toDateProps') toDateProps: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return (this.fromDateProps && Date.parse(this.fromDateProps) > Date.parse(control.value)) ||
      (this.toDateProps && Date.parse(this.toDateProps) < Date.parse(control.value))
      ? { noBefore: true }
      : null;
  }
}
