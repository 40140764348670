import { Component, Input, OnInit } from '@angular/core';
import { Member } from '../../../../../shared/api';

@Component({
  selector: 'fc-member-row',
  templateUrl: './member-row.component.html',
  styleUrls: ['./member-row.component.scss']
})
export class MemberRowComponent implements OnInit {
  @Input() member: Member;
  @Input() config: { showRole?: boolean; showJob?: boolean; viewMode?: 'large' | 'small' };

  ngOnInit() {
    if (!this.config) {
      this.config = { showRole: false, showJob: false, viewMode: 'small' };
    }
    if (!this.config.showJob) {
      this.config.showJob = false;
    }
    if (!this.config.showRole) {
      this.config.showRole = false;
    }
  }
}
