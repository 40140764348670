/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FundingAppealParamsLotPercentAmount } from './fundingAppealParamsLotPercentAmount';
import { UnitParams } from './unitParams';
import { FundingAppealParamsDates } from './fundingAppealParamsDates';
import { BankInformation } from './bankInformation';
import { FundingAppealParamsEdition } from './fundingAppealParamsEdition';
import { FixedParams } from './fixedParams';


export interface FundingAppealParams { 
    notBilled?: Array<string>;
    edition?: FundingAppealParamsEdition;
    dates?: FundingAppealParamsDates;
    fixed?: FixedParams;
    unit?: UnitParams;
    percent?: number;
    deadline?: FundingAppealParams.DeadlineEnum;
    paymentMode?: FundingAppealParams.PaymentModeEnum;
    bankInformation?: BankInformation;
    paymentTerms?: string;
    denomination?: FundingAppealParams.DenominationEnum;
    displayRegistration?: boolean;
    joinExpenses?: boolean;
    displayEmail?: boolean;
    displayPhone?: boolean;
    lotPercentAmounts?: Array<FundingAppealParamsLotPercentAmount>;
}
export namespace FundingAppealParams {
    export type DeadlineEnum = '30_DAYS' | 'TODAY' | 'END_OF_MONTH' | '45_DAYS' | 'END_OF_NEXT_MONTH';
    export const DeadlineEnum = {
        _30Days: '30_DAYS' as DeadlineEnum,
        Today: 'TODAY' as DeadlineEnum,
        EndOfMonth: 'END_OF_MONTH' as DeadlineEnum,
        _45Days: '45_DAYS' as DeadlineEnum,
        EndOfNextMonth: 'END_OF_NEXT_MONTH' as DeadlineEnum
    };
    export type PaymentModeEnum = 'CHECK' | 'TRANSFER';
    export const PaymentModeEnum = {
        Check: 'CHECK' as PaymentModeEnum,
        Transfer: 'TRANSFER' as PaymentModeEnum
    };
    export type DenominationEnum = 'FUNDING_APPEALS' | 'BILL_NUMBER' | 'ASSET';
    export const DenominationEnum = {
        FundingAppeals: 'FUNDING_APPEALS' as DenominationEnum,
        BillNumber: 'BILL_NUMBER' as DenominationEnum,
        Asset: 'ASSET' as DenominationEnum
    };
}


