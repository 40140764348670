import { Member, MemberId } from '../api';
import RoleEnum = MemberId.RoleEnum;
import { Account } from '../../features/account/shared/models/account';

export abstract class EmailsUtils {
  public static emailsToString(to: string, members: Member[], account: Account): { lots: string; all: string } {
    const emails = {
      lots: '',
      all: ''
    };
    if (to === 'all') {
      members
        .filter((member) => [RoleEnum.Member, RoleEnum.Manager].includes(member.role))
        .filter((member) => member.email !== account.email)
        .forEach((member) => {
          emails.lots += member.email + ';';
        });

      members
        .filter((member) => [RoleEnum.GeneralContractor, RoleEnum.ProjectOwner].includes(member.role))
        .filter((member) => member.email !== account.email)
        .forEach((member) => {
          emails.all += member.email + ';';
        });
    } else {
      members
        .filter((member) => [RoleEnum.Member, RoleEnum.Manager].includes(member.role) && member.email !== account.email)
        .forEach((member) => {
          emails.lots += member.email + ';';
        });
    }

    return emails;
  }
}
