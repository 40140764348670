import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConstructionSite, MemberId, MemberQueryResourceService } from '../../../../../shared/api';
import { AuthorityEnum } from '../../../../account/shared/models/authority.enum';

@Component({
  selector: 'fc-moa-moe-invitation-card',
  templateUrl: './moa-moe-invitation-card.component.html',
  styleUrls: ['./moa-moe-invitation-card.component.scss']
})
export class MoaMoeInvitationCardComponent implements OnChanges {
  @Input() constructionSite: ConstructionSite;
  @Input() role: AuthorityEnum;
  @Output() inviteForRole: EventEmitter<AuthorityEnum> = new EventEmitter();
  cardContent;
  members: MemberId[] = [];
  constructor(private memberQueryResourceService: MemberQueryResourceService) {}
  invite(event: Event) {
    event.preventDefault();
    this.inviteForRole.emit(this.role);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.constructionSite && changes.constructionSite.currentValue !== changes.constructionSite.previousValue) {
      this.loadMembers();
    }
  }

  private loadMembers() {
    this.memberQueryResourceService.getMembers(this.constructionSite.id).subscribe((members) => {
      this.members = members.filter((member) => member.role === this.role).slice(0, 2);
    });
  }
}
