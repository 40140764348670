import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ConstructionSiteMenuComponent } from './components/menu/construction-site-menu.component';
import { ConstructionSiteInvitationModalComponent } from './components/invitation/construction-site-invitation-modal.component';
import { MemberModule } from '../../member/member.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../../shared/pipes/pipe.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MoaMoeInvitationCardComponent } from './components/invitation/moa-moe-invitation-card.component';
import { DownloadFileService } from '../../../shared/services/download-file.service';

@NgModule({
  imports: [MemberModule, TranslateModule, PipeModule, CommonModule, RouterModule, NgbModule],
  declarations: [
    ConstructionSiteMenuComponent,
    ConstructionSiteInvitationModalComponent,
    MoaMoeInvitationCardComponent
  ],
  exports: [
    ConstructionSiteMenuComponent,
    ConstructionSiteInvitationModalComponent,
    MoaMoeInvitationCardComponent,
    MemberModule
  ],
  providers: [DownloadFileService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConstructionSiteSharedModule {}
