import { Component, Input, OnInit } from '@angular/core';
import { EmailCheckerService } from '../email-checker/email-checker.service';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Member } from '../../../shared/api';

@Component({
  selector: 'fc-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent {
  members: Member[] = [];
  @Input() emails: {
    email: string;
    isFormatValid: boolean;
    isMxValid: boolean;
    isDisposable: boolean;
    isScoreValid: boolean;
    hasToSendInvitation: boolean;
    isAccountingAccountEmail: boolean;
  }[];

  constructor() {}

  addEmail() {
    this.emails.push({
      email: '',
      isFormatValid: false,
      isMxValid: false,
      isDisposable: false,
      isScoreValid: false,
      hasToSendInvitation: true,
      isAccountingAccountEmail: false
    });
  }

  async updateEmail(event: {
    email: string;
    idx: number;
    isAccountingAccountEmail: boolean;
    hasToSendInvitation: boolean;
  }) {
    this.emails[event.idx].email = event.email;
    this.emails[event.idx].isFormatValid = true;
    this.emails[event.idx].isMxValid = true;
    this.emails[event.idx].isDisposable = false;
    this.emails[event.idx].isScoreValid = true;
    this.emails[event.idx].hasToSendInvitation = event.hasToSendInvitation;
    this.emails[event.idx].isAccountingAccountEmail = event.isAccountingAccountEmail;
  }
}
