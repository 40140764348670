import { Component, Input } from '@angular/core';

@Component({
  selector: 'fc-ui-switch',
  template: `<span class="switch" [ngClass]="{ true: state, false: !state }"
    >{{ 'ui-switch.label.' + state | translate }}<span class="indicator"></span
  ></span>`,
  styleUrls: ['./ui-switch.component.scss']
})
export class UiSwitchComponent {
  @Input() state: boolean;
}
