import { NavbarComponent } from './navbar/navbar.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AccountSharedModule } from '../features/account/shared/account-shared.module';
import { GravatarModule } from 'ngx-gravatar';
import { ConstructionSiteSharedModule } from '../features/construction-site/shared/construction-site-shared.module';
import { AvatarModule } from '../shared/components/avatar/avatar.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '../shared/pipes/pipe.module';
import { LeftNavbarComponent } from './navbar-left/left-navbar.component';

@NgModule({
  imports: [
    ConstructionSiteSharedModule,
    BrowserModule,
    NgbDropdownModule,
    GravatarModule,
    TranslateModule,
    AccountSharedModule,
    AvatarModule,
    RouterModule,
    FormsModule,
    PipeModule
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent]
})
export class CoreModule {}
