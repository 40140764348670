/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Lot } from './lot';
import { ConstructionSite } from './constructionSite';
import { MailjetEvent } from './mailjetEvent';
import { FundingAppealRemindHistory } from './fundingAppealRemindHistory';
import { FundingAppeal } from './fundingAppeal';
import { Document } from './document';


export interface FundingAppealObject { 
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    id?: string;
    isDeductedParticipationFirstModel?: boolean;
    bankInformationId?: string;
    fundingAppeal?: FundingAppeal;
    site?: ConstructionSite;
    agreementMode?: FundingAppealObject.AgreementModeEnum;
    lot?: Lot;
    billDeadlineDate?: string;
    billSendingDate?: string;
    nbRemindMail?: number;
    nbRemindEmail?: number;
    latestRemindDateEmail?: string;
    latestRemindDateMail?: string;
    amount: number;
    amountExclTaxes?: number;
    distribution?: number;
    lastRequestedDate?: string;
    fundingAppealRemindHistory?: Array<FundingAppealRemindHistory>;
    nbReminders?: number;
    state?: FundingAppealObject.StateEnum;
    bill?: Document;
    billNumber?: string;
    notificationEvents?: Array<MailjetEvent>;
}
export namespace FundingAppealObject {
    export type AgreementModeEnum = 'REAL' | 'FIXED' | 'REAL_FIXED' | 'LOT_FIXED';
    export const AgreementModeEnum = {
        Real: 'REAL' as AgreementModeEnum,
        Fixed: 'FIXED' as AgreementModeEnum,
        RealFixed: 'REAL_FIXED' as AgreementModeEnum,
        LotFixed: 'LOT_FIXED' as AgreementModeEnum
    };
    export type StateEnum = 'PENDING' | 'PAID' | 'LATE' | 'EXPIRED';
    export const StateEnum = {
        Pending: 'PENDING' as StateEnum,
        Paid: 'PAID' as StateEnum,
        Late: 'LATE' as StateEnum,
        Expired: 'EXPIRED' as StateEnum
    };
}


