/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

  import { FundingAppealCompany } from '../model/models';
  import { FundingAppealInitRequest } from '../model/models';
  import { FundingAppealItemView } from '../model/models';
  import { FundingAppealItems } from '../model/models';
  import { FundingAppealLightRequest } from '../model/models';
  import { FundingAppealObject } from '../model/models';
  import { FundingAppealRequest } from '../model/models';
  import { MonitoringStats } from '../model/models';
  import { RequestPartFundingAppealsFilters } from '../model/models';
  import { RequestPartFundingAppealsFiltersExtendPageable } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



    @Injectable({
    providedIn: 'root'
    })
    export class FundingAppealQueryResourceService {

  protected basePath = 'https://dev.thefaircost.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
  if (configuration) {
  this.configuration = configuration;
  }
  if (typeof this.configuration.basePath !== 'string') {
  if (typeof basePath !== 'string') {
  basePath = this.basePath;
  }
  this.configuration.basePath = basePath;
  }
  this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
  if (typeof value === "object" && value instanceof Date === false) {
  httpParams = this.addToHttpParamsRecursive(httpParams, value);
  } else {
  httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
  }
  return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
  if (value == null) {
  return httpParams;
  }

  if (typeof value === "object") {
    if (Array.isArray(value)) {
    (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
    } else if (value instanceof Date) {
    if (key != null) {
    httpParams = httpParams.append(key,
    (value as Date).toISOString().substr(0, 10));
    } else {
    throw Error("key may not be null if value is Date");
    }
            } else {
    Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
    httpParams, value[k], key != null ? `${key}.${k}` : k));
    }
  } else if (key != null) {
  httpParams = httpParams.append(key, value);
  } else {
  throw Error("key may not be null if value is not object or array");
  }
  return httpParams;
  }

    /**
        * @param constructionSiteId 
        * @param billToDate 
        * @param percent 
        * @param lotId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public calculateAmount(constructionSiteId: string, billToDate?: string, percent?: number, lotId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<FundingAppealLightRequest>;
    public calculateAmount(constructionSiteId: string, billToDate?: string, percent?: number, lotId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<FundingAppealLightRequest>>;
    public calculateAmount(constructionSiteId: string, billToDate?: string, percent?: number, lotId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<FundingAppealLightRequest>>;
    public calculateAmount(constructionSiteId: string, billToDate?: string, percent?: number, lotId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling calculateAmount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
            if (billToDate !== undefined && billToDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>billToDate, 'bill-to-date');
            }
            if (percent !== undefined && percent !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>percent, 'percent');
            }
            if (lotId !== undefined && lotId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>lotId, 'lot-id');
            }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<FundingAppealLightRequest>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeals/percent/calculate`,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param fundingAppealRequest 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public calculateItems(constructionSiteId: string, fundingAppealRequest?: FundingAppealRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<FundingAppealItems>;
    public calculateItems(constructionSiteId: string, fundingAppealRequest?: FundingAppealRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<FundingAppealItems>>;
    public calculateItems(constructionSiteId: string, fundingAppealRequest?: FundingAppealRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<FundingAppealItems>>;
    public calculateItems(constructionSiteId: string, fundingAppealRequest?: FundingAppealRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling calculateItems.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
    headers = headers.set('Content-Type', httpContentTypeSelected);
    }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.put<FundingAppealItems>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeals/calculate`,
    fundingAppealRequest,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public checkFundingAppealExist(constructionSiteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<boolean>;
    public checkFundingAppealExist(constructionSiteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<boolean>>;
    public checkFundingAppealExist(constructionSiteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<boolean>>;
    public checkFundingAppealExist(constructionSiteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling checkFundingAppealExist.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<boolean>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeals/exist`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param num 
        * @param billNum 
        * @param dryRun 
        * @param amount 
        * @param amountExclTaxes 
        * @param billDeadlineDate 
        * @param billSendingDate 
        * @param fundingAppealAmount 
        * @param percent 
        * @param itemId 
        * @param deductedLotId 
        * @param deductedLotAmount 
        * @param isClosing 
        * @param closingMode 
        * @param participationAmount 
        * @param participationPercent 
        * @param regulate 
        * @param billToDate 
        * @param billLabel 
        * @param fundingAppealBillNum 
        * @param fundingAppealLotAssetId 
        * @param displayParentRegistration 
        * @param displayEmail 
        * @param displayPhone 
        * @param displayBankInformation 
        * @param isDeductedParticipationFirstModel 
        * @param bankInformationId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public downloadFundingAppealBillOffline(constructionSiteId: string, num: number, billNum: string, dryRun?: boolean, amount?: number, amountExclTaxes?: number, billDeadlineDate?: string, billSendingDate?: string, fundingAppealAmount?: number, percent?: number, itemId?: string, deductedLotId?: string, deductedLotAmount?: number, isClosing?: boolean, closingMode?: 'MODE1' | 'MODE2', participationAmount?: number, participationPercent?: number, regulate?: number, billToDate?: string, billLabel?: 'FUNDING_APPEALS' | 'BILL_NUMBER' | 'ASSET', fundingAppealBillNum?: number, fundingAppealLotAssetId?: string, displayParentRegistration?: boolean, displayEmail?: boolean, displayPhone?: boolean, displayBankInformation?: boolean, isDeductedParticipationFirstModel?: boolean, bankInformationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf' | 'application/problem+json'}): Observable<Array<string>>;
    public downloadFundingAppealBillOffline(constructionSiteId: string, num: number, billNum: string, dryRun?: boolean, amount?: number, amountExclTaxes?: number, billDeadlineDate?: string, billSendingDate?: string, fundingAppealAmount?: number, percent?: number, itemId?: string, deductedLotId?: string, deductedLotAmount?: number, isClosing?: boolean, closingMode?: 'MODE1' | 'MODE2', participationAmount?: number, participationPercent?: number, regulate?: number, billToDate?: string, billLabel?: 'FUNDING_APPEALS' | 'BILL_NUMBER' | 'ASSET', fundingAppealBillNum?: number, fundingAppealLotAssetId?: string, displayParentRegistration?: boolean, displayEmail?: boolean, displayPhone?: boolean, displayBankInformation?: boolean, isDeductedParticipationFirstModel?: boolean, bankInformationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf' | 'application/problem+json'}): Observable<HttpResponse<Array<string>>>;
    public downloadFundingAppealBillOffline(constructionSiteId: string, num: number, billNum: string, dryRun?: boolean, amount?: number, amountExclTaxes?: number, billDeadlineDate?: string, billSendingDate?: string, fundingAppealAmount?: number, percent?: number, itemId?: string, deductedLotId?: string, deductedLotAmount?: number, isClosing?: boolean, closingMode?: 'MODE1' | 'MODE2', participationAmount?: number, participationPercent?: number, regulate?: number, billToDate?: string, billLabel?: 'FUNDING_APPEALS' | 'BILL_NUMBER' | 'ASSET', fundingAppealBillNum?: number, fundingAppealLotAssetId?: string, displayParentRegistration?: boolean, displayEmail?: boolean, displayPhone?: boolean, displayBankInformation?: boolean, isDeductedParticipationFirstModel?: boolean, bankInformationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/pdf' | 'application/problem+json'}): Observable<HttpEvent<Array<string>>>;
    public downloadFundingAppealBillOffline(constructionSiteId: string, num: number, billNum: string, dryRun?: boolean, amount?: number, amountExclTaxes?: number, billDeadlineDate?: string, billSendingDate?: string, fundingAppealAmount?: number, percent?: number, itemId?: string, deductedLotId?: string, deductedLotAmount?: number, isClosing?: boolean, closingMode?: 'MODE1' | 'MODE2', participationAmount?: number, participationPercent?: number, regulate?: number, billToDate?: string, billLabel?: 'FUNDING_APPEALS' | 'BILL_NUMBER' | 'ASSET', fundingAppealBillNum?: number, fundingAppealLotAssetId?: string, displayParentRegistration?: boolean, displayEmail?: boolean, displayPhone?: boolean, displayBankInformation?: boolean, isDeductedParticipationFirstModel?: boolean, bankInformationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/pdf' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling downloadFundingAppealBillOffline.');
        }
        if (num === null || num === undefined) {
        throw new Error('Required parameter num was null or undefined when calling downloadFundingAppealBillOffline.');
        }
        if (billNum === null || billNum === undefined) {
        throw new Error('Required parameter billNum was null or undefined when calling downloadFundingAppealBillOffline.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
            if (billNum !== undefined && billNum !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>billNum, 'bill-num');
            }
            if (dryRun !== undefined && dryRun !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>dryRun, 'dry-run');
            }
            if (amount !== undefined && amount !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>amount, 'amount');
            }
            if (amountExclTaxes !== undefined && amountExclTaxes !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>amountExclTaxes, 'amountExclTaxes');
            }
            if (billDeadlineDate !== undefined && billDeadlineDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>billDeadlineDate, 'bill-deadline-date');
            }
            if (billSendingDate !== undefined && billSendingDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>billSendingDate, 'bill-sending-date');
            }
            if (fundingAppealAmount !== undefined && fundingAppealAmount !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>fundingAppealAmount, 'funding-appeal-amount');
            }
            if (percent !== undefined && percent !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>percent, 'percent');
            }
            if (itemId !== undefined && itemId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>itemId, 'item-id');
            }
            if (deductedLotId !== undefined && deductedLotId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>deductedLotId, 'deducted-lot-id');
            }
            if (deductedLotAmount !== undefined && deductedLotAmount !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>deductedLotAmount, 'deducted-lot-amount');
            }
            if (isClosing !== undefined && isClosing !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>isClosing, 'is-closing');
            }
            if (closingMode !== undefined && closingMode !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>closingMode, 'closing-mode');
            }
            if (participationAmount !== undefined && participationAmount !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>participationAmount, 'participation-amount');
            }
            if (participationPercent !== undefined && participationPercent !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>participationPercent, 'participation-percent');
            }
            if (regulate !== undefined && regulate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>regulate, 'regulate');
            }
            if (billToDate !== undefined && billToDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>billToDate, 'bill-to-date');
            }
            if (billLabel !== undefined && billLabel !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>billLabel, 'bill-label');
            }
            if (fundingAppealBillNum !== undefined && fundingAppealBillNum !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>fundingAppealBillNum, 'funding-appeal-bill-num');
            }
            if (fundingAppealLotAssetId !== undefined && fundingAppealLotAssetId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>fundingAppealLotAssetId, 'funding-appeal-lot-asset-id');
            }
            if (displayParentRegistration !== undefined && displayParentRegistration !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>displayParentRegistration, 'display-parent-registration');
            }
            if (displayEmail !== undefined && displayEmail !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>displayEmail, 'display-email');
            }
            if (displayPhone !== undefined && displayPhone !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>displayPhone, 'display-phone');
            }
            if (displayBankInformation !== undefined && displayBankInformation !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>displayBankInformation, 'display-bank-information');
            }
            if (isDeductedParticipationFirstModel !== undefined && isDeductedParticipationFirstModel !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>isDeductedParticipationFirstModel, 'is-deducted-participation-first-model');
            }
            if (bankInformationId !== undefined && bankInformationId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>bankInformationId, 'bank-information-id');
            }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/pdf',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/services/bill/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeals/${encodeURIComponent(String(num))}/bills/offline`,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealAll(constructionSiteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<FundingAppealItems>;
    public getFundingAppealAll(constructionSiteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<FundingAppealItems>>;
    public getFundingAppealAll(constructionSiteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<FundingAppealItems>>;
    public getFundingAppealAll(constructionSiteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getFundingAppealAll.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<FundingAppealItems>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeal-items/all`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param requestPartFundingAppealsFilters 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealAllItems(requestPartFundingAppealsFilters?: RequestPartFundingAppealsFilters, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<FundingAppealObject>>;
    public getFundingAppealAllItems(requestPartFundingAppealsFilters?: RequestPartFundingAppealsFilters, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<FundingAppealObject>>>;
    public getFundingAppealAllItems(requestPartFundingAppealsFilters?: RequestPartFundingAppealsFilters, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<FundingAppealObject>>>;
    public getFundingAppealAllItems(requestPartFundingAppealsFilters?: RequestPartFundingAppealsFilters, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
    headers = headers.set('Content-Type', httpContentTypeSelected);
    }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<Array<FundingAppealObject>>(`${this.configuration.basePath}/services/public/construction/construction-sites/funding-appeal-items`,
    requestPartFundingAppealsFilters,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param requestPartFundingAppealsFiltersExtendPageable 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealAllItemsPageable(requestPartFundingAppealsFiltersExtendPageable?: RequestPartFundingAppealsFiltersExtendPageable, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<FundingAppealObject>>;
    public getFundingAppealAllItemsPageable(requestPartFundingAppealsFiltersExtendPageable?: RequestPartFundingAppealsFiltersExtendPageable, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<FundingAppealObject>>>;
    public getFundingAppealAllItemsPageable(requestPartFundingAppealsFiltersExtendPageable?: RequestPartFundingAppealsFiltersExtendPageable, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<FundingAppealObject>>>;
    public getFundingAppealAllItemsPageable(requestPartFundingAppealsFiltersExtendPageable?: RequestPartFundingAppealsFiltersExtendPageable, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
    headers = headers.set('Content-Type', httpContentTypeSelected);
    }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<Array<FundingAppealObject>>(`${this.configuration.basePath}/services/public/construction/construction-sites/funding-appeal-items/pageable`,
    requestPartFundingAppealsFiltersExtendPageable,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param requestPartFundingAppealsFilters 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealAllItemsStats(requestPartFundingAppealsFilters?: RequestPartFundingAppealsFilters, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<MonitoringStats>;
    public getFundingAppealAllItemsStats(requestPartFundingAppealsFilters?: RequestPartFundingAppealsFilters, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<MonitoringStats>>;
    public getFundingAppealAllItemsStats(requestPartFundingAppealsFilters?: RequestPartFundingAppealsFilters, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<MonitoringStats>>;
    public getFundingAppealAllItemsStats(requestPartFundingAppealsFilters?: RequestPartFundingAppealsFilters, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      
        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
    headers = headers.set('Content-Type', httpContentTypeSelected);
    }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<MonitoringStats>(`${this.configuration.basePath}/services/public/construction/construction-sites/funding-appeal-items/stats`,
    requestPartFundingAppealsFilters,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealClosing(constructionSiteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<FundingAppealRequest>;
    public getFundingAppealClosing(constructionSiteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<FundingAppealRequest>>;
    public getFundingAppealClosing(constructionSiteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<FundingAppealRequest>>;
    public getFundingAppealClosing(constructionSiteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getFundingAppealClosing.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<FundingAppealRequest>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeals/closing`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param id 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealItem(constructionSiteId: string, id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<FundingAppealObject>;
    public getFundingAppealItem(constructionSiteId: string, id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<FundingAppealObject>>;
    public getFundingAppealItem(constructionSiteId: string, id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<FundingAppealObject>>;
    public getFundingAppealItem(constructionSiteId: string, id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getFundingAppealItem.');
        }
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling getFundingAppealItem.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<FundingAppealObject>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeal-items/${encodeURIComponent(String(id))}`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param states 
        * @param proof 
        * @param minAmount 
        * @param maxAmount 
        * @param fromDate 
        * @param toDate 
        * @param lotIds 
        * @param fundingAppealIds 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealItems(constructionSiteId: string, states: Array<'PENDING' | 'PAID' | 'LATE' | 'EXPIRED'>, proof?: 'ALL' | 'BILL' | 'ASSET', minAmount?: number, maxAmount?: number, fromDate?: string, toDate?: string, lotIds?: Array<string>, fundingAppealIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<FundingAppealObject>>;
    public getFundingAppealItems(constructionSiteId: string, states: Array<'PENDING' | 'PAID' | 'LATE' | 'EXPIRED'>, proof?: 'ALL' | 'BILL' | 'ASSET', minAmount?: number, maxAmount?: number, fromDate?: string, toDate?: string, lotIds?: Array<string>, fundingAppealIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<FundingAppealObject>>>;
    public getFundingAppealItems(constructionSiteId: string, states: Array<'PENDING' | 'PAID' | 'LATE' | 'EXPIRED'>, proof?: 'ALL' | 'BILL' | 'ASSET', minAmount?: number, maxAmount?: number, fromDate?: string, toDate?: string, lotIds?: Array<string>, fundingAppealIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<FundingAppealObject>>>;
    public getFundingAppealItems(constructionSiteId: string, states: Array<'PENDING' | 'PAID' | 'LATE' | 'EXPIRED'>, proof?: 'ALL' | 'BILL' | 'ASSET', minAmount?: number, maxAmount?: number, fromDate?: string, toDate?: string, lotIds?: Array<string>, fundingAppealIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getFundingAppealItems.');
        }
        if (states === null || states === undefined) {
        throw new Error('Required parameter states was null or undefined when calling getFundingAppealItems.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (states) {
            states.forEach((element) => {
              queryParameters = this.addToHttpParams(queryParameters,
                <any>element, 'states');
              })
          }
            if (proof !== undefined && proof !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>proof, 'proof');
            }
            if (minAmount !== undefined && minAmount !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>minAmount, 'minAmount');
            }
            if (maxAmount !== undefined && maxAmount !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>maxAmount, 'maxAmount');
            }
            if (fromDate !== undefined && fromDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>fromDate, 'fromDate');
            }
            if (toDate !== undefined && toDate !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
          <any>toDate, 'toDate');
            }
        if (lotIds) {
            lotIds.forEach((element) => {
              queryParameters = this.addToHttpParams(queryParameters,
                <any>element, 'lotIds');
              })
          }
        if (fundingAppealIds) {
            fundingAppealIds.forEach((element) => {
              queryParameters = this.addToHttpParams(queryParameters,
                <any>element, 'fundingAppealIds');
              })
          }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<FundingAppealObject>>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeal-items`,
          {
            params: queryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealItemsCompany(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<FundingAppealCompany>>;
    public getFundingAppealItemsCompany(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<FundingAppealCompany>>>;
    public getFundingAppealItemsCompany(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<FundingAppealCompany>>>;
    public getFundingAppealItemsCompany(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<FundingAppealCompany>>(`${this.configuration.basePath}/services/public/construction/construction-sites/funding-appeal-items-company`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getFundingAppealView(constructionSiteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<FundingAppealItemView>>;
    public getFundingAppealView(constructionSiteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<FundingAppealItemView>>>;
    public getFundingAppealView(constructionSiteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<FundingAppealItemView>>>;
    public getFundingAppealView(constructionSiteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getFundingAppealView.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<Array<FundingAppealItemView>>(`${this.configuration.basePath}/services/public/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeal-items/view`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
        * @param lotId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public getItemsByLotId(constructionSiteId: string, lotId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<Array<FundingAppealObject>>;
    public getItemsByLotId(constructionSiteId: string, lotId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<Array<FundingAppealObject>>>;
    public getItemsByLotId(constructionSiteId: string, lotId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<Array<FundingAppealObject>>>;
    public getItemsByLotId(constructionSiteId: string, lotId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling getItemsByLotId.');
        }
        if (lotId === null || lotId === undefined) {
        throw new Error('Required parameter lotId was null or undefined when calling getItemsByLotId.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.post<Array<FundingAppealObject>>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeals/lot/${encodeURIComponent(String(lotId))}`,
    null,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

    /**
        * @param constructionSiteId 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public initializeFA(constructionSiteId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<FundingAppealInitRequest>;
    public initializeFA(constructionSiteId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<FundingAppealInitRequest>>;
    public initializeFA(constructionSiteId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<FundingAppealInitRequest>>;
    public initializeFA(constructionSiteId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (constructionSiteId === null || constructionSiteId === undefined) {
        throw new Error('Required parameter constructionSiteId was null or undefined when calling initializeFA.');
        }

      let headers = this.defaultHeaders;

      let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
      if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
        'application/problem+json'
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      }
      if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if(httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('text'))) {
            responseType_ = 'text';
        }
        if (httpHeaderAcceptSelected && (httpHeaderAcceptSelected.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || httpHeaderAcceptSelected.startsWith('application/zip')) || (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('application/pdf'))) {
          responseType_ = 'blob';
        }

        return this.httpClient.get<FundingAppealInitRequest>(`${this.configuration.basePath}/services/construction/construction-sites/${encodeURIComponent(String(constructionSiteId))}/funding-appeals/init`,
          {
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
                  }
          );
          }

}
